package data

import react.useState

sealed class ResponseState<out T> {
    object None : ResponseState<Nothing>() {
        override fun toString() = this::class.simpleName ?: super.toString()
    }

    object Loading : ResponseState<Nothing>() {
        override fun toString() = this::class.simpleName ?: super.toString()
    }

    data class Success<T>(val data: T) : ResponseState<T>()
    data class Error(val exception: Throwable) : ResponseState<Nothing>()
}

inline fun <reified T> ResponseState<T>.none() = this as? ResponseState.None
inline fun <reified T> ResponseState<T>.loading() = this as? ResponseState.Loading
inline fun <reified T> ResponseState<T>.success(): T? = (this as? ResponseState.Success<T>)?.data
inline fun <reified T> ResponseState<T>.error() = (this as? ResponseState.Error)

inline fun <reified T> ResponseState<T>.isNone() = this is ResponseState.None
inline fun <reified T> ResponseState<T>.isLoading() = this is ResponseState.Loading
inline fun <reified T> ResponseState<T>.isSuccess() = this is ResponseState.Success
inline fun <reified T> ResponseState<T>.isError() = this is ResponseState.Error

fun <T> useResponseState(initialValue: ResponseState<T> = ResponseState.None) = useState(initialValue)

fun <T, R> ResponseState<T>.map(block: (T) -> R) = when (this) {
    is ResponseState.Success -> ResponseState.Success(block(data))
    is ResponseState.Loading -> ResponseState.Loading
    is ResponseState.None -> ResponseState.None
    is ResponseState.Error -> ResponseState.Error(exception)
}