package framework

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.http.*

fun <T : Api> ktorClientApi(block: () -> T, httpClient: HttpClient): T =
    block().apply { initialize(RouteContextKtorClient(httpClient)) }

private class RouteContextKtorClient(val httpClient: HttpClient) : RouteContext() {
    override fun newContext(): RouteContext = RouteContextKtorClient(httpClient)
}

fun RouteContext.requireKtorClient() =
    (this as? RouteContextKtorClient)?.httpClient ?: throw Exception("Not a ktorClientApi.")

operator fun <T : Route> RouteParameter<T>.invoke(value: String) = creator(value)

suspend inline operator fun <reified R> Endpoint.Get<R>.invoke(
    block: HttpRequestBuilder.() -> Unit = {}
) = context.requireKtorClient().get(path, block).body<R>()

suspend inline operator fun Endpoint.Head.invoke(
    block: HttpRequestBuilder.() -> Unit = {}
) = context.requireKtorClient().head(path, block)

suspend inline operator fun <reified B : Any, reified R> Endpoint.Post<B, R>.invoke(
    body: B, block: HttpRequestBuilder.() -> Unit = {}
) = context.requireKtorClient().post(path) {
    jsonBody(body)
    block()
}.body<R>()

suspend inline operator fun <reified B : Any> Endpoint.Put<B>.invoke(
    body: B, block: HttpRequestBuilder.() -> Unit = {}
) = context.requireKtorClient().put(path) {
    jsonBody(body)
    block()
}

suspend inline operator fun <reified R> Endpoint.Delete<R>.invoke(
    block: HttpRequestBuilder.() -> Unit = {}
) = context.requireKtorClient().delete(path, block).body<R>()

suspend inline operator fun <reified B : Any, reified R> Endpoint.Patch<B, R>.invoke(
    body: B, block: HttpRequestBuilder.() -> Unit = {}
) = context.requireKtorClient().patch(path) {
    jsonBody(body)
    block()
}.body<R>()

inline fun <reified T> HttpRequestBuilder.jsonBody(body: T) {
    header(HttpHeaders.ContentType, ContentType.Application.Json)
    setBody(body)
}