package components

import common.Icon
import csstype.px
import emotion.react.css
import model.IdItem
import model.PatchItem
import react.ChildrenBuilder
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.td

external interface MyTablePatchProps : Props {
    var data: List<PatchItem<*>>
    var selectedId: String?
    var onSelected: (PatchItem<*>) -> Unit
    var headerCreator: (ChildrenBuilder) -> Unit
    var rowCreator: (ChildrenBuilder, index: Int, item: PatchItem<*>) -> Unit
    var wordBreak: Boolean?
}

val MyTablePatch = FC<MyTablePatchProps> { props ->
    MyTable {
        data = props.data
        selectedId = props.selectedId
        onSelected = props.onSelected.unsafeCast<(IdItem) -> Unit>()
        wordBreak = props.wordBreak
        headerCreator = { childrenBuilder ->
            props.headerCreator(childrenBuilder)
            childrenBuilder.apply {
                ReactHTML.th {
                    css { maxWidth = 40.px; width = 40.px }
                    Icon("change_history")
                }
            }
        }
        rowCreator = { childrenBuilder, index, item ->
            props.rowCreator(childrenBuilder, index, item as PatchItem<*>)
            childrenBuilder.apply {
                td {
                    css { maxWidth = 40.px; width = 40.px }
                    item.changeStatus.icon.let {
                        Icon(it?.name, it?.type)
                    }
                }
            }
        }
    }
}