package views

import csstype.*
import data.useUser
import emotion.react.css
import mui.material.*
import mui.material.Size
import mui.system.ResponsiveStyleValue
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.span

val Index = FC<Props> {
    val user = useUser()
    div {
        css {
            display = Display.flex
            alignItems = AlignItems.center
            flexDirection = FlexDirection.column
        }
        h2 { +"Hello, ${user?.givenName}!" }
        ButtonGroup {
            variant = ButtonGroupVariant.text
            size = Size.large
            orientation = Orientation.vertical
            Button {
                +"Operation Messages"
                href = Nav.operation
            }
            Button {
                +"Opening Hours"
                href = Nav.openingHours
            }
            Button {
                +"Partner Emails"
                href = Nav.email
            }
        }
        span {
            css {
                fontSize = 12.px
                marginTop = 10.px
                color = Color("#ccc")
            }
            +"Version: 0.2.2"
        }
    }
}