package data

import Backend
import framework.ktorClientApi
import io.ktor.client.*
import io.ktor.client.engine.js.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.browser.window

private fun URLBuilder.forceWindowSsl() {
    if (window.location.protocol == "https:") forceSsl()
}

private fun URLBuilder.forceSsl() {
    protocol = when (protocol) {
        URLProtocol.HTTP -> URLProtocol.HTTPS
        URLProtocol.WS -> URLProtocol.WSS
        else -> protocol
    }
}

private val httpClientApi = HttpClient(Js) {
    expectSuccess = true
    install(ContentNegotiation) { json(json) }
    install(DefaultRequest) {
        url {
            host = window.location.hostname
            port = window.location.port.takeIf { it.isNotBlank() }?.toInt() ?: DEFAULT_PORT
            forceWindowSsl()
        }
    }
}

val backend = ktorClientApi(::Backend, httpClientApi)
