package components.editor

import components.ButtonDropdown
import csstype.px
import emotion.react.css
import externals.SlateReactModule.useSlate
import helpers.MessagePlaceholderType
import helpers.PlaceholderMapping
import mui.material.ButtonGroup
import mui.material.ButtonGroupVariant
import mui.material.Size
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.useMemo
import styles.outlineBorder

external interface EditorToolbarProps : Props {
    var toolbarDefinition: List<ToolbarButton>?
    var placeholderMapping: PlaceholderMapping?
}

data class ToolbarMenuOption(val type: MessagePlaceholderType, val value: String)
data class ToolbarMenu(val name: String, val options: List<ToolbarMenuOption>)

val EditorToolbar = FC<EditorToolbarProps> { props ->
    val editor = useSlate()

    val toolbarDefinition = useMemo(props.toolbarDefinition, props.placeholderMapping) {
        val mapping = props.placeholderMapping ?: emptyMap()
        val def = props.toolbarDefinition ?: emptyList()
        def.mapNotNull { btn ->
            btn.types.mapNotNull { type ->
                mapping[type]?.let { ToolbarMenuOption(type, it.capitalized) }
            }.takeUnless {
                it.isEmpty()
            }?.let {
                ToolbarMenu(btn.name, it)
            }
        }
    }

    if (toolbarDefinition.isEmpty()) return@FC

    div {
        css {
            borderBottom = outlineBorder
            marginBottom = 10.px
            paddingBottom = 6.px
        }
        ButtonGroup {
            variant = ButtonGroupVariant.text
            size = Size.small
            toolbarDefinition.forEach { menu ->
                ButtonDropdown {
                    name = menu.name
                    options = menu.options.map { it.value }
                    onClick = {
                        val option = menu.options[it]
                        insertPlaceholderElement(editor, option.type, option.value)
                    }
                }
            }
        }
    }
}