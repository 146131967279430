package model

import views.operationcreate.Service

enum class OperationServices(
    override val label: String,
    override val disabled: Boolean = false,
    val defaultEnabled: Boolean = true
) : Service {
    WORKPLACE("Workplace", disabled = false, defaultEnabled = true) {
        override fun getItemValue(item: OperationItem): OperationServiceResult<*> = item.workplace
    },
    WEBSITE("Website") {
        override fun getItemValue(item: OperationItem): OperationServiceResult<*> = item.website
    },
    DISPLAYS("Displays") {
        override fun getItemValue(item: OperationItem): OperationServiceResult<*> = item.displays
    },
    EMAIL_PARTNERS("Email Partners", disabled = true, defaultEnabled = false) {
        override fun getItemValue(item: OperationItem): OperationServiceResult<*> = item.emailPartners
    },
    GOOGLE("Google", disabled = true, defaultEnabled = false) {
        override fun getItemValue(item: OperationItem): OperationServiceResult<*> = item.google
    },
    FACEBOOK("Facebook", disabled = true, defaultEnabled = false) {
        override fun getItemValue(item: OperationItem): OperationServiceResult<*> = item.facebook
    };

    fun getSelectValue(values: Array<Boolean>) = values[ordinal]
    abstract fun getItemValue(item: OperationItem): OperationServiceResult<*>
}