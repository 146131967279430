package components

import csstype.*
import emotion.react.css
import kotlinx.datetime.Instant
import mui.material.Stack
import react.FC
import react.Props
import react.dom.html.ReactHTML.span
import utils.DateFormat
import utils.toDate

external interface LastUpdatedProps : Props {
    var lastUpdatedName: String?
    var lastUpdatedTime: Instant?
    var createdTime: Instant?
}

val LastUpdatedView = FC<LastUpdatedProps> {
    Stack {
        css {
            fontSize = 0.8.rem
            margin = Margin(vertical = 20.px, horizontal = 10.px)
            textAlign = TextAlign.center
            display = Display.block
        }
        if (it.createdTime == it.lastUpdatedTime) {
            it.lastUpdatedName?.let { name ->
                it.lastUpdatedTime?.let { time ->
                    span { +"Created: $name | ${DateFormat.DAY_MONTH_YEAR.eng(time.toDate())}" }
                }
            }
        } else {
            it.createdTime?.let { span { +"Created: ${DateFormat.DAY_MONTH_YEAR.eng(it.toDate())}" } }
            it.lastUpdatedName?.let { name ->
                it.lastUpdatedTime?.let { time ->
                    span { +"Last Updated: $name | ${DateFormat.DAY_MONTH_YEAR.eng(time.toDate())}" }
                }
            }
        }
    }
}