package components.time

import copy
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toInstant
import kotlinx.datetime.toLocalDateTime
import model.LocalTime
import model.localTime
import react.FC

external interface MyTimePickerPropsInstant : TimePickerBaseStyleProps {
    var value: Instant
    var timeZone: TimeZone
    var onChange: ((Instant) -> Unit)?
    var options: List<ClosedRange<LocalTime>>?
    var minuteStep: Int?
}

val TimePickerInstant = FC<MyTimePickerPropsInstant> { props ->
    val localValue = props.value.toLocalDateTime(props.timeZone)
    TimePickerLocal {
        +props
        this.value = localValue.localTime
        this.options = props.options
        this.onChange = props.onChange?.let { change ->
            {
                change(
                    localValue.copy(hour = it.hour, minute = it.minute, second = 0, nanoSecond = 0)
                        .toInstant(props.timeZone)
                )
            }
        }
    }
}

