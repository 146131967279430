package components.time

import model.LocalTime
import react.FC
import react.useCallback
import react.useMemo

external interface MyTimePickerLocalProps : TimePickerBaseStyleProps {
    var value: LocalTime?
    var onChange: ((LocalTime) -> Unit)?
    var options: List<ClosedRange<LocalTime>>?
    var minuteStep: Int?
}

val TimePickerLocal = FC<MyTimePickerLocalProps>("TimePickerLocal") { props ->
    val onChange: (String) -> Unit = useCallback(props.onChange) {
        props.onChange?.invoke(LocalTime.fromMinutes(it.toInt()))
    }
    val options = useMemo(props.minuteStep, props.options) {
        val step = props.minuteStep ?: 1
        val valid = props.options?.map {
            it.start.toMinutes()..it.endInclusive.toMinutes()
        } ?: listOf(0..24 * 60 - step)
        valid.map { range ->
            (range step step).map {
                TimePickerBaseOption(it.toString(), LocalTime.fromMinutes(it).toString())
            }
        }
    }
    TimePickerBase {
        +props
        this.value = props.value?.toMinutes().toString()
        this.options = options
        this.onChange = onChange
    }
}
