package model.operation

data class OperationTimeInfo(
    val start: Localized,
    val end: Localized,
    val open: Localized
)

interface Localized {
    val eng: String
    val nor: String

    fun engCap() = eng.replaceFirstChar { it.uppercase() }
    fun norCap() = nor.replaceFirstChar { it.uppercase() }
}

data class LocalizedContent(
    override val eng: String,
    override val nor: String
) : Localized