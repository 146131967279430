package utils

import kotlinx.datetime.Instant
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days

class InstantIterator(
    start: Instant,
    private val endInclusive: Instant,
    private val step: Duration = 1.days
) : Iterator<Instant> {
    private var next = start
    override fun hasNext() = if (step.isNegative()) next >= endInclusive else next <= endInclusive
    override fun next() = next.also { next += step }
}

class InstantProgression(
    override val start: Instant,
    override val endInclusive: Instant,
    val step: Duration = 1.days
) : Iterable<Instant>, ClosedRange<Instant> {
    override fun iterator() = InstantIterator(start, endInclusive, step)

    override fun toString(): String {
        return if (step.isPositive()) "$start..$endInclusive step $step" else "$start downTo $endInclusive step ${-step}"
    }
}

infix fun InstantProgression.step(step: Duration): InstantProgression {
    check(step.isPositive()) { "Step must be positive: $step" }
    return InstantProgression(start, endInclusive, if (this.step.isNegative()) -step else step)
}

operator fun Instant.rangeTo(other: Instant) = InstantProgression(this, other)
infix fun Instant.downTo(other: Instant) = InstantProgression(this, other, (-1).days)
