package helpers

import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.plus
import model.*
import model.operation.*
import utils.*


object OperationTimeHelpers {
    const val pickerMinuteStep = 10

    private fun isNow(date: LocalDate, openingHours: OpeningHoursHelper, now: LocalDateTime) =
        openingHours.getOpeningHours(date)?.contains(now) ?: (date == now.date)

    fun nowTime() = TimePickerHelper.now(pickerMinuteStep)
    fun nowTimeLocal() = nowTime().toLocal()

    fun initTime(openingHours: OpeningHoursHelper) =
        openingHours.nextValidTimeRange(nowTime()).let {
            OperationTimeJs(
                start = it.start,
                end = it.endInclusive,
                startType = OperationTimeStartType.DATE,
                endType = OperationTimeEndType.DATE
            )
        }

    fun resolve(
        openingHours: OpeningHoursHelper,
        time: OperationTimeJs,
        baseTime: LocalDateTime
    ): OperationTimeResolved {
        val (start, end, open) = resolveInternal(openingHours, time, baseTime)
        return OperationTimeResolved(
            (start ?: baseTime).toInstant(),
            end?.toInstant(),
            open?.toInstant()
        )
    }

    fun timeInfo(
        time: OperationTimeJs,
        openingHours: OpeningHoursHelper,
        baseTime: LocalDateTime
    ): OperationTimeInfo {
        val (start, end, open) = resolveInternal(openingHours, time, baseTime)
        val dateTimeFormatter = DateTimeFormatter(DateFormatter(baseTime, timeZone))
        return OperationTimeInfo(
            start?.let { dateTimeFormatter.get(it) } ?: LocalizedContent("now", "nå"),
            end?.let { dateTimeFormatter.get(it) } ?: LocalizedContent("undecided", "ikke bestemt"),
            open?.let { dateTimeFormatter.get(it) } ?: LocalizedContent("undecided", "ikke bestemt")
        )
    }

    private fun resolveInternal(
        openingHours: OpeningHoursHelper,
        time: OperationTimeJs,
        baseTime: LocalDateTime
    ): Triple<LocalDateTime?, LocalDateTime?, LocalDateTime?> {
        val start = when (time.startType) {
            OperationTimeStartType.DATE -> {
                if (isNow(time.start.toDate(), openingHours, now = baseTime)) {
                    null
                } else {
                    openingHours.getOpeningHours(time.start.toLocal().date)?.start
                        ?: time.start.toLocal().date.toLocalDateTime(LocalTime(8, 0))
                }
            }
            OperationTimeStartType.DATE_AND_TIME -> time.start.toLocal()
        }
        val end: LocalDateTime? = when (time.endType) {
            OperationTimeEndType.DATE -> {
                openingHours.getOpeningHours(time.end.toLocal().date)?.endInclusive
            }
            OperationTimeEndType.DATE_AND_TIME -> time.end.toLocal()
            OperationTimeEndType.UNDECIDED -> null
        }
        val open = when (time.endType) {
            OperationTimeEndType.DATE -> {
                openingHours.getNextOpeningHours(from = time.end.toDate().plus(DateTimeUnit.DAY)).start
            }
            OperationTimeEndType.DATE_AND_TIME -> {
                val local = time.end.toLocal()
                if (openingHours.getOpeningHours(local.date)?.contains(local) != false) local
                else {
                    openingHours.getNextOpeningHours(from = local.date.plus(DateTimeUnit.DAY)).start
                }
            }
            OperationTimeEndType.UNDECIDED -> null
        }

        return Triple(start, end, open)
    }

}