package views.operationcreate

import csstype.AlignItems
import csstype.Display
import csstype.JustifyContent
import emotion.react.css
import model.CheckData
import mui.material.Checkbox
import react.FC
import react.Props
import react.dom.html.ReactHTML
import components.editor.MyEditor

external interface CheckStepProps : Props {
    var prompt: String
    var value: CheckData
    var onChange: (CheckData) -> Unit
}

val CheckStep = FC<CheckStepProps> { props ->
    ReactHTML.div {
        css {
            display = Display.flex
            justifyContent = JustifyContent.flexStart
            alignItems = AlignItems.center
        }
        ReactHTML.p { +props.prompt }
        Checkbox {
            value = props.value.checked
            onChange = { _, checked -> props.onChange(props.value.copy(checked = checked)) }
        }
    }
    MyEditor {
        value = props.value.note
        onChange = { props.onChange(props.value.copy(note = it)) }
        placeholder = "Notat"
        //showToolbar = false
    }
}