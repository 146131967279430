@file:Suppress("NOTHING_TO_INLINE")

package utils

import csstype.Angle
import csstype.Color
import csstype.Percentage
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.cancel
import kotlinx.coroutines.launch
import mui.material.BaseTextFieldProps
import mui.material.useMediaQuery
import react.ReactNode
import react.StateInstance
import react.useEffectOnce
import react.useMemo
import validations.ValidationResult
import kotlin.coroutines.CoroutineContext
import kotlin.coroutines.EmptyCoroutineContext

@Suppress("NOTHING_TO_INLINE")
inline fun <T, R> T.css(): R = unsafeCast<R>()

@Suppress("NOTHING_TO_INLINE")
inline fun Double.number() = csstype.number(this)

@Suppress("NOTHING_TO_INLINE")
inline fun <T : Any> T.important() = csstype.important(this)

@Suppress("NOTHING_TO_INLINE")
inline fun hsl(
    hue: Angle,
    saturation: Percentage,
    light: Percentage,
): Color = "hsl(${hue}, ${saturation}, ${light})".unsafeCast<Color>()

var BaseTextFieldProps.validationResult: ValidationResult
    get() = if (error == true && helperText != null) {
        ValidationResult.Fail(helperText.unsafeCast<String>())
    } else {
        ValidationResult.Ok
    }
    set(value) {
        when (value) {
            is ValidationResult.Fail -> {
                error = true
                helperText = value.message.unsafeCast<ReactNode?>()
            }

            else -> {}
        }
    }

inline val <T> StateInstance<T>.value
    get() = component1()

inline val <T> StateInstance<T>.setter
    get() = component2()

inline operator fun <T> StateInstance<T>.invoke(noinline block: (T) -> T) = setter(block)
inline operator fun <T> StateInstance<T>.invoke(value: T) = setter(value)
inline fun <T> StateInstance<T>.set(value: T) = component2().invoke(value)

fun useDesktop() = useMediaQuery("(min-width:800px) and (orientation: landscape)")

fun useMainScope(): CoroutineScope {
    val scope = useMemo { MainScope() }
    useEffectOnce { cleanup { scope.cancel() } }
    return scope
}

fun CoroutineScope.useLaunchOnce(
    context: CoroutineContext = EmptyCoroutineContext,
    block: suspend CoroutineScope.() -> Unit
) = useEffectOnce { launch(context = context, block = block) }