package model

import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.toInstant
import utils.InstantProgression
import utils.rangeTo
import utils.timeZone

/**
 *
 * Selectable time:
 * - Size 0: No selectable time.
 * - Size 1: Selectable time corresponding to date opening
 * - Size 2:
 *      [0]: Selectable time corresponding to prev date opening
 *      [1]: Selectable time corresponding to date opening
 **/

data class DateSelectableTime(
    val date: LocalDate,
    val openingHours: ClosedRange<LocalDateTime>?,
    val selectableTime: List<ClosedRange<LocalTime>>  // May be empty.
) {
    val selectableInstant: List<InstantProgression> = selectableTime.map {
        it.start.toLocalDateTime(date).toInstant(timeZone)..it.endInclusive.toLocalDateTime(date).toInstant(timeZone)
    }

    fun isClosed() = openingHours == null
}