package data

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import react.StateInstance
import react.StateSetter
import utils.useLaunchOnce

suspend fun <T> load(setter: StateSetter<ResponseState<T>>, action: suspend () -> T) {
    setter(ResponseState.Loading)
    setter(
        try {
            ResponseState.Success(action())
        } catch (e: Throwable) {
            e.printStackTrace()
            ResponseState.Error(e)
        }
    )
}

fun <T> CoroutineScope.load(setter: StateSetter<ResponseState<T>>, action: suspend () -> T) =
    launch { data.load(setter, action) }

fun <T> CoroutineScope.load(instance: StateInstance<ResponseState<T>>, action: suspend () -> T) =
    launch { data.load(instance.component2(), action) }

fun <T> CoroutineScope.useLoadOnce(setter: StateSetter<ResponseState<T>>, action: suspend () -> T) {
    useLaunchOnce { data.load(setter, action) }
}
fun <T> CoroutineScope.useLoadOnce(instance: StateInstance<ResponseState<T>>, action: suspend () -> T) {
    useLaunchOnce { data.load(instance.component2(), action) }
}