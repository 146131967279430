package views.operation

import coerceIn
import components.time.MyDatePicker
import components.time.TimePickerBaseStyleProps
import components.time.TimePickerInstant
import csstype.*
import emotion.react.css
import emotion.styled.styled
import helpers.OpeningHoursHelper
import helpers.OperationTimeHelpers
import kotlinx.datetime.Instant
import kotlinx.datetime.toLocalDateTime
import kotlinx.js.jso
import model.operation.OperationTimeEndType
import model.operation.OperationTimeJs
import model.operation.OperationTimeStartType
import mu.KotlinLogging
import mui.material.FormLabel
import mui.material.Size
import mui.material.Stack
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.responsive
import react.FC
import react.Props
import react.StateSetter
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import styles.OptionStyles.NestedOption
import styles.OptionStyles.Option
import styles.OptionStyles.OptionGroup
import styles.selected
import utils.toDate
import utils.toLocal
import views.openinghours.OpeningTimeNowView
import views.openinghours.OpeningTimeView
import views.operationcreate.useMemo
import utils.timeZone as defaultTimeZone

private val logger = KotlinLogging.logger("OperationTimePicker")

external interface OperationTimePickerProps : Props {
    var value: OperationTimeJs
    var openingHours: OpeningHoursHelper?
    var valueSetter: StateSetter<OperationTimeJs?>?
    var disabledStart: Boolean?
    var disabledEnd: Boolean?
    var readOnlyStart: Boolean?
    var readOnlyEnd: Boolean?
}

private val DatePickerContainer = div.styled { _, _ ->
    display = Display.flex
    alignItems = AlignItems.center
    padding = 8.px
}
private val TimePickerContainer = div.styled { _, _ ->
    flexGrow = number(1.0)
    NestedOption {
        padding = Padding(4.px, 8.px, 8.px, 8.px)
        display = Display.flex
        flexDirection = FlexDirection.column
        alignItems = AlignItems.stretch
        "> span" {
            margin = 5.px
        }
    }
}

private val timeStyleProps = jso<TimePickerBaseStyleProps> {
    hideIcon = true
    size = Size.small
}

//TODO: Enforce a gap between times (not too important).

val OperationTimePicker = FC<OperationTimePickerProps>("OperationTimePicker") { props ->
    val readOnlyStart = props.readOnlyStart ?: false
    val readOnlyEnd = props.readOnlyEnd ?: false
    val startDate = props.value.start.toDate()
    val endDate = props.value.end.toDate()
    val now = useMemo { OperationTimeHelpers.nowTime() }
    val nowLocal = useMemo { now.toLocal() }
    val minTime = props.openingHours.useMemo { it?.nextValidTimeRange(now)?.start }

    val selectableStart = props.openingHours?.getSelectableTime(startDate)
    val selectableEnd = props.openingHours?.getSelectableTime(endDate)
    val showOpeningAsNow = selectableStart?.openingHours?.contains(nowLocal)?.takeUnless { readOnlyStart } ?: false

    fun change(block: OperationTimeJs.() -> OperationTimeJs) =
        props.valueSetter?.invoke { it?.block() ?: it }

    fun onClickStart(type: OperationTimeStartType) = change {
        copy(startType = type)
    }

    fun onClickEnd(type: OperationTimeEndType?) = change {
        copy(
            endType = type ?: when (endType) {
                OperationTimeEndType.DATE -> OperationTimeEndType.DATE
                OperationTimeEndType.DATE_AND_TIME -> OperationTimeEndType.DATE_AND_TIME
                OperationTimeEndType.UNDECIDED -> OperationTimeEndType.DATE
            }
        )
    }

    fun onChangeStart(newType: OperationTimeStartType?, newStart: Instant) {
        val dateNew = newStart.toLocalDateTime(defaultTimeZone).date
        props.openingHours?.getSelectableTime(dateNew)?.let { selectableNew ->
            change {
                val newStartType = newType ?: startType
                val newStartValid = newStart.coerceIn(selectableNew.selectableInstant)
                val newEndValid = end.coerceAtLeast(newStartValid)
                copy(start = newStartValid, end = newEndValid, startType = newStartType)
            }
        }
    }

    fun onChangeEnd(newType: OperationTimeEndType?, newEnd: Instant) {
        val dateNew = newEnd.toLocalDateTime(defaultTimeZone).date
        props.openingHours?.getSelectableTime(dateNew)?.let { selectableNew ->
            change {
                val newEndValid = newEnd.coerceIn(selectableNew.selectableInstant)
                val newStartValid = start.coerceAtMost(newEndValid)
                val endType = newType ?: when (endType) {
                    OperationTimeEndType.DATE -> OperationTimeEndType.DATE
                    OperationTimeEndType.DATE_AND_TIME -> OperationTimeEndType.DATE_AND_TIME
                    OperationTimeEndType.UNDECIDED -> OperationTimeEndType.DATE
                }
                copy(start = newStartValid, end = newEndValid, endType = endType)
            }
        }
    }

    Stack {
        spacing = responsive(2)
        div {
            FormLabel { +"From" }
            OptionGroup {
                Option {
                    DatePickerContainer {
                        selected(true)
                        MyDatePicker {
                            value = props.value.start
                            onChange = { onChangeStart(null, it) }
                            this.minTime = minTime.takeUnless { readOnlyStart }
                            readOnly = props.readOnlyStart
                            disabled = props.disabledStart
                        }
                    }
                    TimePickerContainer {
                        val dateSelected = props.value.startType == OperationTimeStartType.DATE
                        if (dateSelected || !readOnlyStart) {
                            NestedOption {
                                selected(dateSelected)
                                onClick = { onClickStart(OperationTimeStartType.DATE) }
                                if (showOpeningAsNow) {
                                    Typography {
                                        variant = TypographyVariant.overline
                                        span {
                                            css { textDecoration = TextDecoration.lineThrough; marginRight = 5.px }
                                            +"Opening"
                                        }
                                        span { +"Now" }
                                    }
                                    OpeningTimeNowView {
                                        +timeStyleProps
                                        this.now = nowLocal
                                    }
                                } else {
                                    Typography {
                                        variant = TypographyVariant.overline
                                        +"Opening"
                                    }
                                    OpeningTimeView {
                                        +timeStyleProps
                                        start = true
                                        this.selectableTime = selectableStart
                                    }
                                }
                            }
                        }
                        val timeSelected = props.value.startType == OperationTimeStartType.DATE_AND_TIME
                        if (timeSelected || !readOnlyStart) {
                            NestedOption {
                                selected(timeSelected)
                                onClick = { onClickStart(OperationTimeStartType.DATE_AND_TIME) }
                                Typography {
                                    variant = TypographyVariant.overline
                                    +"Custom"
                                }
                                TimePickerInstant {
                                    +timeStyleProps
                                    value = props.value.start
                                    onChange = { onChangeStart(OperationTimeStartType.DATE_AND_TIME, it) }
                                    minuteStep = OperationTimeHelpers.pickerMinuteStep
                                    options = selectableStart?.selectableTime
                                    disabled = props.disabledStart
                                    readOnly = props.readOnlyStart
                                    timeZone = defaultTimeZone
                                }
                            }
                        }
                    }
                }
            }
        }
        div {
            FormLabel { +"To" }
            OptionGroup {
                val undecidedSelected = props.value.endType == OperationTimeEndType.UNDECIDED
                if (!undecidedSelected || !readOnlyEnd) {
                    Option {
                        DatePickerContainer {
                            selected(!undecidedSelected)
                            onClick = { onClickEnd(null) }
                            MyDatePicker {
                                value = props.value.end
                                onChange = { onChangeEnd(null, it) }
                                this.minTime = minTime.takeUnless { readOnlyEnd }
                                readOnly = props.readOnlyEnd
                                disabled = props.disabledEnd
                            }
                        }
                        TimePickerContainer {
                            val dateSelected = props.value.endType == OperationTimeEndType.DATE
                            if (dateSelected || !readOnlyEnd) {
                                NestedOption {
                                    selected(dateSelected)
                                    onClick = { onClickEnd(OperationTimeEndType.DATE) }
                                    Typography {
                                        variant = TypographyVariant.overline
                                        +"Closing"
                                    }
                                    OpeningTimeView {
                                        +timeStyleProps
                                        start = false
                                        this.selectableTime = selectableEnd
                                    }
                                }
                            }
                            val timeSelected = props.value.endType == OperationTimeEndType.DATE_AND_TIME
                            if (timeSelected || !readOnlyEnd) {
                                NestedOption {
                                    selected(timeSelected)
                                    onClick = { onClickEnd(OperationTimeEndType.DATE_AND_TIME) }
                                    Typography {
                                        variant = TypographyVariant.overline
                                        +"Custom"
                                    }
                                    TimePickerInstant {
                                        +timeStyleProps
                                        value = props.value.end
                                        onChange = { onChangeEnd(OperationTimeEndType.DATE_AND_TIME, it) }
                                        minuteStep = OperationTimeHelpers.pickerMinuteStep
                                        options = selectableEnd?.selectableTime
                                        disabled = props.disabledEnd
                                        readOnly = props.readOnlyEnd
                                        timeZone = defaultTimeZone
                                    }
                                }
                            }
                        }
                    }
                }
                if (undecidedSelected || !readOnlyEnd) {
                    Option {
                        selected(undecidedSelected)
                        onClick = { onClickEnd(OperationTimeEndType.UNDECIDED) }
                        Typography {
                            css { padding = 10.px }
                            variant = TypographyVariant.overline
                            +"Undecided (until deleted)"
                        }
                    }
                }
            }
        }
    }
}
