package views.operationcreate

import common.asLabel
import csstype.Display
import csstype.FlexDirection
import csstype.FlexWrap
import csstype.rem
import emotion.react.css
import mui.material.Checkbox
import mui.material.FormControlLabel
import org.w3c.dom.HTMLInputElement
import react.FC
import react.Props
import react.create
import react.dom.events.ChangeEvent
import react.dom.html.ReactHTML.div

interface Service {
    val label: String
    val disabled: Boolean
}

external interface ServiceChoiceProps : Props {
    var services: Array<out Service>
    var values: Array<Boolean>
    var onChange: (Array<Boolean>) -> Unit
    var disabled: Boolean
}

val ServiceChoice = FC<ServiceChoiceProps> { props ->
    div {
        css {
            display = Display.flex
            flexDirection = FlexDirection.column
            flexWrap = FlexWrap.wrap
            marginLeft = 1.5.rem
        }
        props.services.forEachIndexed { index, service ->
            FormControlLabel {
                label = service.label.asLabel()
                control = Checkbox.create {
                    checked = props.values[index]
                    disabled = service.disabled || props.disabled
                    onChange = { _: ChangeEvent<HTMLInputElement>, check: Boolean ->
                        props.onChange(props.values.copyOf().also { it[index] = check })
                    }
                }
            }
        }
    }
}
