package views.openinghours

import common.Icon
import common.IconType
import components.LastUpdatedView
import components.time.MyDatePickerMemo
import csstype.*
import emotion.react.css
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.datetime.toInstant
import kotlinx.datetime.toLocalDateTime
import model.LocalTime
import model.OpeningDay
import model.toLocalDateTime
import mui.material.IconButton
import mui.material.Size
import react.*
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import styles.outlineBorder
import utils.css
import utils.grid
import utils.timeZone
import views.OpeningHoursPatchItem

external interface OpeningHoursItemProps : Props {
    var current: Boolean
    var item: OpeningHoursPatchItem
    var dispatch: Dispatch<OpeningHoursAction>
}

private fun OpeningHoursItemProps.update(action: (OpeningHoursPatchItem) -> OpeningHoursPatchItem) {
    dispatch(OpeningHoursAction.UpdateItem(item.itemId, action))
}

val OpeningHoursItemView = FC<OpeningHoursItemProps>("OpeningHoursItemView") { props ->
    val nowTime = useMemo { Clock.System.now() }

    val onStartChange = useCallback(props.dispatch, props.item.itemId) { value: Instant ->
        props.update { it.copy(details = it.details.copy(startDate = value.toLocalDateTime(timeZone).date)) }
    }
    val onDelete = useCallback(props.dispatch, props.item.itemId) { _: MouseEvent<*, *> ->
        props.update { it.copy(deleted = !it.deleted) }
    }
    val onHoursChange = locationValues.map { location ->
        weekdayValues.map { weekDay ->
            useCallback(props.dispatch, props.item.itemId) { value: OpeningDay ->
                props.update {
                    val week = location.prop.get(it.details)
                    it.copy(details = location.copy(it.details, weekDay.copy(week, value)))
                }
            }
        }
    }

    div {
        css {
            paddingBottom = 20.px
        }
        div {
            key = "header"
            css {
                display = Display.flex
                justifyContent = JustifyContent.spaceBetween
                alignItems = AlignItems.center
                marginBottom = 20.px
            }
            if (props.current) {
                div {
                    css {
                        padding = Padding(8.px, 14.px)
                        borderRadius = 4.px
                        border = outlineBorder
                        width = 9.3.rem
                        height = 40.px
                    }
                    +"Current"
                }
                span {
                    css { fontSize = 0.8.rem }
                    +""
                }
            } else {
                MyDatePickerMemo {
                    value = props.item.details.startDate.toLocalDateTime(LocalTime.ZERO).toInstant(timeZone)
                    onChange = onStartChange
                    minTime = nowTime
                    label = "Effective From"
                    size = Size.small
                }
                IconButton {
                    Icon("delete", if (props.item.deleted) IconType.FILLED else IconType.OUTLINED)
                    onClick = onDelete
                }
            }
        }
        div {
            css {
                display = Display.grid
                alignItems = AlignItems.center
                justifyItems = JustifyItems.center
                columnGap = 5.px.css()
                rowGap = 10.px.css()
                fontSize = 14.px
                fontWeight = 500.css()
            }
            locationValues.forEachIndexed { i, s ->
                div {
                    key = "loc$i"
                    css { grid(1, i + 2) }
                    +s.displayName
                }
            }
            weekdayValues.forEachIndexed { j, s ->
                div {
                    key = "week$j"
                    css { grid(j + 2, 1) }
                    +s.displayName
                }
            }
            locationValues.forEachIndexed { i, location ->
                val week = location.prop.get(props.item.details)
                weekdayValues.forEachIndexed { j, weekDay ->
                    div {
                        key = "$i/$j"
                        css { grid(j + 2, i + 2) }
                        OpeningDayViewMemo {
                            data = weekDay.prop.get(week)
                            onChange = onHoursChange[i][j]
                        }
                    }
                }
            }
        }
        LastUpdatedView {
            lastUpdatedName = props.item.lastUpdatedName
            lastUpdatedTime = props.item.lastUpdatedTime
        }
    }
}
