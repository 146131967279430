package views.login

import common.formEventHandler
import common.onChange
import common.asLabel
import csstype.rem
import data.ResponseState
import data.error
import data.isLoading
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Link
import mui.material.TextField
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ButtonType
import react.dom.html.InputType
import react.dom.html.ReactHTML

external interface LoginCodeProps : Props {
    var phone: String
    var code: String
    var codeResponse: ResponseState<String>
    var onCodeChange: (String) -> Unit
    var onSubmit: () -> Unit
    var onGoBack: () -> Unit
}

val LoginCodeView = FC<LoginCodeProps> { props ->
    props.codeResponse.error()?.let {
        ReactHTML.p { +it.exception.toString() }
    }
    ReactHTML.p { +"Input the code sent to ${props.phone}" }
    ReactHTML.form {
        onSubmit = formEventHandler { props.onSubmit() }
        TextField {
            key = "code"
            label = "Code".asLabel()
            autoFocus = true
            type = InputType.text
            disabled = props.codeResponse.isLoading()
            placeholder = "0000"
            fullWidth = true
            value = props.code
            onChange = { props.onCodeChange(it.target.value) }
        }
        Button {
            +"Submit"
            sx { marginTop = 1.rem }
            variant = ButtonVariant.contained
            type = ButtonType.submit
            fullWidth = true
            disabled = props.codeResponse.isLoading()
        }
        ReactHTML.p {
            +"Did not receive code? "
            Link {
                +"Go back"
                href = "#"
                onClick = { props.onGoBack() }
            }
        }
    }
}