package views

import common.Icon
import components.updates.ResponseStateView
import csstype.*
import data.*
import emotion.react.css
import framework.invoke
import helpers.OpeningHoursHelper
import helpers.OperationTimeHelpers
import model.*
import model.operation.*
import mui.material.*
import mui.material.Size
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.router.useNavigate
import utils.*
import views.header.Footer
import views.operation.OperationTimePicker
import views.operation.Templates
import views.operationcreate.*

enum class OperationStep(val disableNext: Boolean = false) {
    TYPE(true), TIME, TIME_SEND, DISTRIBUTION, CONTENT, WEATHER
}

enum class OperationType(
    vararg extraSteps: OperationStep,
    val defaultTemplate: Template? = null,
    val selectable: Boolean = true
) {
    NONE(selectable = false),
    WEATHER(OperationStep.WEATHER, defaultTemplate = Templates.WEATHER),
    OTHER;

    val steps = listOf(OperationStep.TYPE) + extraSteps + listOf(
        OperationStep.TIME,
        OperationStep.TIME_SEND,
        OperationStep.DISTRIBUTION,
        OperationStep.CONTENT,
    )
}

val selectableOperationTypes = OperationType.values().filter { it.selectable }

fun ChildrenBuilder.step(
    activeStep: OperationStep,
    step: OperationStep,
    block: @ReactDsl ChildrenBuilder.() -> Unit
) {
    if (activeStep == step) div {
        css {
            display = if (activeStep == step) Display.flex else None.none
            flexDirection = FlexDirection.column
        }
        block(this@div)
    }
}


val CreateOperationStepper = FC<Props> {
    val scope = useMainScope()
    val desktop = useDesktop()
    val navigate = useNavigate()

    // Reponses:
    val openingHoursResponse = useResponseState<OpeningHoursHelper>()
    val createResponse = useResponseState<OperationItem>()

    // Data state:
    var type by useState(OperationType.NONE)
    val (activeStepCounter, setActiveStep) = useState(0)
    val weather = useState {
        WeatherData(CheckData.Init, CheckData.Init, CheckData.Init, CheckData.Init, CheckData.Init)
    }
    val time = useState<OperationTimeJs>()
    val messageNor = useState("")
    val messageEng = useState("")
    val serviceValues = useState { OperationServices.values().map { it.defaultEnabled }.toTypedArray() }
    val activeStep = type.steps[activeStepCounter]

    scope.useLoadOnce(openingHoursResponse) {
        backend.openingHours.get().let {
            OpeningHoursHelper(it, minTime = OperationTimeHelpers.nowTimeLocal())
        }
    }

    fun prevStep() = setActiveStep { it - 1 }
    fun nextStep() = setActiveStep {
        (it + 1).also { newStep ->
            when (type.steps[newStep]) {
                OperationStep.TIME -> {
                    openingHoursResponse.value.success()?.let { openingHours ->
                        time(OperationTimeHelpers.initTime(openingHours))
                    }
                }
                else -> {}
            }
        }
    }

    fun createRequest() = CreateOperationRequest(
        time = time.value!!.toServer(),
        messageNor = messageNor.value,
        messageEng = messageEng.value,
        services = OperationServicesRequest(
            workplace = OperationServices.WORKPLACE.getSelectValue(serviceValues.value),
            website = OperationServices.WEBSITE.getSelectValue(serviceValues.value),
            displays = OperationServices.DISPLAYS.getSelectValue(serviceValues.value),
            emailPartners = OperationServices.EMAIL_PARTNERS.getSelectValue(serviceValues.value),
            google = OperationServices.GOOGLE.getSelectValue(serviceValues.value),
            facebook = OperationServices.FACEBOOK.getSelectValue(serviceValues.value)
        ),
        weather = when (type) {
            OperationType.WEATHER -> weather.value
            else -> null
        }
    )

    fun submit() = scope.load(createResponse) {
        backend.operation.post(createRequest()).also {
            navigate(Nav.operation)
        }
    }

    ResponseStateView { state = createResponse.value }
    ResponseStateView { state = openingHoursResponse.value }
    val openingHours = openingHoursResponse.value.success() ?: return@FC

    div {
        css {
            display = Display.flex
            flexDirection = FlexDirection.column
        }
        div {
            css {
                padding = 10.px
                if (desktop) minHeight = 70.vh
            }
            step(activeStep, OperationStep.TYPE) {
                p { +"What type of message is this?" }
                selectableOperationTypes.forEach { operationType ->
                    Button {
                        variant = ButtonVariant.outlined
                        +operationType.name
                        onClick = {
                            type = operationType
                            operationType.defaultTemplate?.let {
                                messageNor(it.contentNor)
                                messageEng(it.contentEng)
                            }
                            nextStep()
                        }
                    }
                }
            }
            step(activeStep, OperationStep.WEATHER) {
                OperationWeatherStep {
                    value = weather.value
                    setter = weather.setter
                }
            }
            step(activeStep, OperationStep.TIME) {
                p { +"At what time does the message apply?" }
                time.value?.let { timeValue ->
                    OperationTimePicker {
                        value = timeValue
                        valueSetter = time.setter
                        this.openingHours = openingHours
                    }
                }
            }
            step(activeStep, OperationStep.TIME_SEND) {
                p { +"When is the message going to be sent/displayed?" }
                OperationSendTimePicker {}
            }
            step(activeStep, OperationStep.DISTRIBUTION) {
                p { +"How do you want to distribute the message?" }
                ServiceChoice {
                    services = OperationServices.values()
                    values = serviceValues.value
                    onChange = { serviceValues(it) }
                    disabled = false
                }
            }
            step(activeStep, OperationStep.CONTENT) {
                p { +"Create your message." }
                time.value?.let { timeValue ->
                    OperationContentEditor {
                        valueNor = messageNor.value
                        valueEng = messageEng.value
                        onValueNorChanged = { messageNor(it) }
                        onValueEngChanged = { messageEng(it) }
                        templates = Templates.LIST
                        this.time = timeValue
                        this.openingHours = openingHours
                        this.services = serviceValues.value
                    }
                }
            }
            Footer {}
        }
        MobileStepper {
            css {
                backgroundColor = hsl(0.deg, 0.pct, 95.pct).important()
                borderRadius = 4.px.important()
                marginTop = 16.px.important()
            }
            this.activeStep = activeStepCounter
            elevation = 2
            variant = MobileStepperVariant.progress
            steps = type.steps.size
            position = if (desktop) MobileStepperPosition.static else MobileStepperPosition.bottom
            backButton = Button.create {
                size = Size.small
                onClick = {
                    if (activeStepCounter == 0) navigate(Nav.operation)
                    else prevStep()
                }
                Icon("keyboard_arrow_left")
                +"Back"
            }
            nextButton = Button.create {
                val finalStep = activeStepCounter == type.steps.size - 1
                size = Size.small
                disabled = activeStep.disableNext ||
                        (activeStep == OperationStep.CONTENT && (messageNor.value.isEmpty() || messageEng.value.isEmpty()))
                onClick = { if (finalStep) submit() else nextStep() }
                if (finalStep) +"Submit" else +"Next"
                Icon("keyboard_arrow_right")
            }
        }
    }
}
