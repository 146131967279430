package utils

import kotlinx.html.*
import model.Language
import model.operation.OperationTimeInfo

object EmailContentCreator {
    class EmailContent(
        val heading: String,
        vararg val body: String,
        val css: CssBuilder.() -> Unit = {}
    ) {
        fun html() = createEmailHtml(this)
        fun htmlBody() = createEmailBody(this)
        fun text() = createEmailPlainText(this)
    }

    fun contentOperationNew(message: String, timeInfo: OperationTimeInfo, language: Language) = when (language) {
        Language.NOR -> EmailContent("Driftsmelding", message, timeInfo.nor())
        Language.ENG -> EmailContent("Operation Message", message, timeInfo.eng())
    }

    fun contentOperationUpdate(message: String, language: Language) = when (language) {
        Language.NOR -> EmailContent("Driftsmelding oppdatert", message)
        Language.ENG -> EmailContent("Operation Message Update", message)
    }

    fun contentOperationCancelled(language: Language) = when (language) {
        Language.NOR -> EmailContent("Driftsmelding fjernet", "Driftsmeldingen gjelder ikke lenger.")
        Language.ENG -> EmailContent("Operation Message Removed", "The message no longer applies.")
    }

    private fun OperationTimeInfo.nor() = "Fra: ${start.norCap()} | Åpner: ${open.norCap()}"
    private fun OperationTimeInfo.eng() = "From: ${start.engCap()} | Open: ${open.engCap()}"

    fun createEmailPlainText(vararg content: EmailContent) =
        content.joinToString("\n") { "${it.heading}\n${it.body}" }

    fun createEmailHtml(vararg content: EmailContent) = emailHtml { emailBody(content) }
    fun createEmailBody(vararg content: EmailContent) = emailBody { emailBody(content) }

    private fun BODY.emailBody(content: Array<out EmailContent>) {
        table {
            val spacing = Dimension(8)
            css {
                +"max-width:800px"
                +"border:none"
                +"border-spacing:0"
            }
            tr {
                td {
                    css {
                        +"vertical-align:top"
                        +"width:15%"
                        +"min-width:80px"
                        +"padding-top:$spacing"
                        +"padding-left:$spacing"
                        +"padding-right:${spacing * 2}"
                    }
                    img(src = "https://portal.fjellheisen.no/logo-square.png") {
                        css { +"width:100%" }
                    }
                }
                td {
                    css {
                        +"vertical-align:top"
                    }
                    content.forEach { content ->
                        div {
                            css(content.css)
                            h1 {
                                css {
                                    +"font-size:18px"
                                    +"margin-top:0px"
                                    +"margin-bottom:6px"
                                }
                                +content.heading
                            }
                            content.body.forEach {
                                p {
                                    css {
                                        +"font-size:14px"
                                        +"margin-top:0px"
                                        +"margin-bottom:$spacing"
                                        +"margin-right:$spacing"
                                        +"white-space:pre-line"
                                    }
                                    +it
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}