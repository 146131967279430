package views.login

import csstype.AlignItems
import csstype.Display
import csstype.FlexDirection
import data.*
import framework.invoke
import model.LoginInitRequest
import model.LoginInitResponse
import model.LoginVerifyRequest
import mu.KotlinLogging
import mui.material.Box
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.useState
import utils.set
import utils.useMainScope
import utils.value

external interface LoginProps : Props {
    var onSuccess: (() -> Unit)?
}

private val logger = KotlinLogging.logger("Login")

val LoginView = FC<LoginProps>("LoginView") { props ->
    val scope = useMainScope()
    var area by useState("47")
    var number by useState("")
    var code by useState("")
    val initResponse = useResponseState<LoginInitResponse>()
    val codeResponse = useResponseState<String>()
    val phone = "+${area.trim(' ')}${number.trim(' ')}"

    Box {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.column
            alignItems = AlignItems.center
        }
        ReactHTML.h3 { +"Login" }
        codeResponse.value.success()?.let {
            ReactHTML.p { +"Completed. Redirecting..." }
            ReactHTML.p { +"If you are not redirected, try refreshing the page." }
        } ?: initResponse.value.success()?.let { initResponseSuccess ->
            LoginCodeView {
                this.codeResponse = codeResponse.value
                this.phone = phone
                this.code = code
                this.onCodeChange = { code = it }
                this.onSubmit = {
                    scope.load(codeResponse) {
                        backend.auth.login.verifyPost(
                            LoginVerifyRequest(initResponseSuccess.requestId, code.toInt())
                        ).also {
                            props.onSuccess?.invoke()
                        }
                    }
                }
                this.onGoBack = {
                    code = ""
                    initResponse.set(ResponseState.None)
                    codeResponse.set(ResponseState.None)
                }
            }
        } ?: run {
            LoginPhoneView {
                this.state = initResponse.value
                this.area = area
                this.number = number
                this.onChangeArea = { area = it }
                this.onChangeNumber = { number = it }
                this.onSubmit = {
                    logger.info { "Submitting phone: $phone" }
                    scope.load(initResponse) {
                        backend.auth.login.initPost(LoginInitRequest(phone))
                    }
                }
            }
        }
    }
}
