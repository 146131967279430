package components

import csstype.pct
import kotlinx.js.jso
import mui.material.*
import mui.system.sx
import react.FC
import react.Props

external interface SuccessSnackBarProps : Props {
    var message: String?
    var open: Boolean?
    var onClose: (() -> Unit)?
}

val SuccessSnackBar = FC<SuccessSnackBarProps> { props ->
    Snackbar {
        this.onClose = { _, _ -> props.onClose?.invoke() }
        anchorOrigin = jso {
            this.horizontal = SnackbarOriginHorizontal.center
            this.vertical = SnackbarOriginVertical.top
        }
        open = props.open
        autoHideDuration = 5000
        Alert {
            this.onClose = { props.onClose?.invoke() }
            severity = AlertColor.success
            sx { width = 100.pct }
            +(props.message ?: "")
        }
    }
}