package data

import CommonConstants
import common.deleteCookie
import common.getCookie
import kotlinx.browser.window
import kotlinx.js.timers.Timeout
import kotlinx.js.timers.setTimeout
import kotlinx.serialization.decodeFromString
import model.MyJwt
import react.useEffectOnce
import react.useState
import utils.value
import views.operationcreate.set

sealed class UserResult {
    data class User(val user: MyJwt) : UserResult()
    object Missing : UserResult() {
        override fun toString() = "Missing"
    }

    data class Failure(val e: Throwable) : UserResult()
}

fun useUser() = (useUserResult() as? UserResult.User)?.user

fun useUserResult(): UserResult {
    val user = useState(getUser())
    useEffectOnce {
        var end = false
        fun checkUser(): Timeout = setTimeout({
            if (!end) {
                val newUser = getUser()
                user.component2().set {
                    checkUser()
                    if (this == newUser) this else newUser
                }
            }
        }, 1000)
        checkUser()
        cleanup { end = true }
    }
    return user.value
}

private fun getUser() = try {
    val res = getCookie(CommonConstants.keyAccessTokenPayload)?.let {
        json.decodeFromString<MyJwt>(window.atob(it))
    }
    if (res != null) UserResult.User(res) else UserResult.Missing
} catch (e: Throwable) {
    UserResult.Failure(e)
}

fun logoutUser() = deleteCookie(CommonConstants.keyAccessTokenPayload)