package model

import kotlinx.datetime.Instant
import utils.ChangeStatus

interface PatchItem<T> : IdItem {
    val details: T
    val lastUpdatedName: String?
    val lastUpdatedTime: Instant?
    val oldItem: PatchItem<T>?
    val valid: Boolean
    val changeStatus: ChangeStatus
        get() = oldItem.let {
            when {
                it == null -> ChangeStatus.CREATED
                it.details == details && it.deleted == deleted -> ChangeStatus.EQUAL
                deleted -> ChangeStatus.UPDATED_DELETED
                else -> ChangeStatus.UPDATED
            }
        }
}

data class PatchRequest<T>(
    val created: List<T>,
    val updated: Map<String, T>,
    val deleted: Set<String>
)

fun <T> createPatchGeneric(data: Map<String, PatchItem<T>>): PatchRequest<T> {
    val created = mutableListOf<T>()
    val updated = mutableMapOf<String, T>()
    val deleted = mutableSetOf<String>()
    data.values.forEach {
        when (it.changeStatus) {
            ChangeStatus.EQUAL -> {}
            ChangeStatus.CREATED -> if (!it.deleted) created.add(it.details)
            ChangeStatus.UPDATED -> updated[it.itemId] = it.details
            ChangeStatus.UPDATED_DELETED -> deleted.add(it.itemId)
        }
    }
    return PatchRequest(created, updated, deleted)
}
