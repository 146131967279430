package views

import components.updates.FetchAndUpdateHeader
import components.updates.UpdateToolbar
import data.*
import framework.invoke
import kotlinx.datetime.*
import model.*
import randomId
import react.*
import utils.ChangeStatus
import utils.timeZone
import utils.useMainScope
import views.openinghours.*

data class OpeningHoursPatchItem(
    override val details: OpeningHoursItemDetails,
    override val itemId: String,
    override val lastUpdatedName: String?,
    override val lastUpdatedTime: Instant?,
    override val oldItem: PatchItem<OpeningHoursItemDetails>?,
    override val deleted: Boolean,
    override val valid: Boolean = true
) : PatchItem<OpeningHoursItemDetails>

fun OpeningHoursItem.toPatchItem() = OpeningHoursPatchItem(
    details = details,
    itemId = itemId,
    deleted = deleted,
    lastUpdatedName = lastUpdatedName,
    lastUpdatedTime = lastUpdatedTime,
    oldItem = OpeningHoursPatchItem(
        details = details,
        itemId = itemId,
        lastUpdatedName = lastUpdatedName,
        lastUpdatedTime = lastUpdatedTime,
        oldItem = null,
        deleted = deleted
    )
)

fun createPatch(data: OpeningHoursMap) = createPatchGeneric(data).run { OpeningHoursPatch(created, updated, deleted) }

val OpeningHoursView = FC<Props>("OpeningHoursView") {
    val scope = useMainScope()
    val (fetchState, fetchStateSetter) = useResponseState<OpeningHoursMap>()
    val (updateState, updateStateSetter) = useResponseState<Unit>()
    var selectedId: String? by useState()
    val (data, dataDispatch) = useReducer(OpeningHoursDataReducer, emptyMap())

    scope.useLoadOnce(fetchStateSetter) {
        backend.openingHours.get()
            .map { it.toPatchItem() }
            .associateBy { it.itemId }
            .also { dataDispatch(OpeningHoursAction.Update(it)) }
            .also { selectedId = it.minByOrNull { it.value.details.startDate }?.value?.itemId }
    }

    FetchAndUpdateHeader {
        this.heading = "Opening Hours Plan"
        this.fetchStates = listOf(fetchState)
        this.updateMessage = "Opening Hours Updated"
        this.updateState = updateState
        this.updateStateReset = { updateStateSetter(it) }
    }

    val originalData = fetchState.success() ?: return@FC
    val dataList = data.values
        .filter { !it.deleted || it.changeStatus == ChangeStatus.UPDATED_DELETED }
        .sortedBy { it.details.startDate }

    if (dataList.isEmpty()) return@FC

    UpdateToolbar {
        key = "UpdateToolbar"
        this.changes = dataList.any { it.changeStatus != ChangeStatus.EQUAL }
        this.onAdd = {
            val now = Clock.System.todayAt(timeZone)
            val base = dataList.last()
            val new = OpeningHoursPatchItem(
                details = base.details.copy(startDate = maxOf(base.details.startDate, now).plus(DateTimeUnit.DAY)),
                lastUpdatedTime = null,
                lastUpdatedName = null,
                itemId = randomId(),
                deleted = false,
                oldItem = null
            )
            dataDispatch(OpeningHoursAction.NewItem(new))
            selectedId = new.itemId
        }
        this.onSave = {
            scope.load(updateStateSetter) {
                val newItems = backend.openingHours.patch(createPatch(data))
                    .map { it.toPatchItem() }
                    .associateBy { it.itemId }
                val newData = (originalData + newItems)
                fetchStateSetter(ResponseState.Success(newData))
                dataDispatch(OpeningHoursAction.Update(newData))
            }
        }
        this.onDiscard = {
            dataDispatch(OpeningHoursAction.Update(originalData))
            updateStateSetter(ResponseState.None)
        }
    }
    OpeningHoursTable {
        key = "ListView"
        this.data = dataList
        this.selectedId = selectedId
        this.onSelected = { selectedId = it.itemId }
    }
    selectedId?.let { data[it] }?.let { selectedItem ->
        OpeningHoursItemView {
            key = "ItemView"
            this.item = selectedItem
            this.current = dataList.first().itemId == selectedItem.itemId
            this.dispatch = dataDispatch
        }
    }
}
