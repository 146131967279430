package components.updates

import components.SuccessSnackBar
import csstype.*
import data.ResponseState
import data.isLoading
import data.isSuccess
import emotion.react.css
import mui.material.Alert
import mui.material.AlertColor
import mui.material.LinearProgress
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2

external interface FetchAndUpdateResponseStateProps : Props {
    var heading: String?
    var fetchStates: List<ResponseState<*>>

    var updateMessage: String?
    var updateState: ResponseState<*>
    var updateStateReset: ((ResponseState.None) -> Unit)?
}

val FetchAndUpdateHeader = FC<FetchAndUpdateResponseStateProps> { props ->
    val allStates = props.fetchStates + props.updateState
    props.heading?.let {
        h2 {
            css { marginBottom = 0.px }
            +it
        }
    }
    SuccessSnackBar {
        message = props.updateMessage ?: "Updated"
        open = props.updateState.isSuccess()
        onClose = { props.updateStateReset?.invoke(ResponseState.None) }
    }
    div {
        css {
            display = Display.flex
            flexDirection = FlexDirection.column
            alignItems = AlignItems.stretch
            "> *" { margin = 10.px }
        }
        LinearProgress {
            css {
                visibility = if (allStates.any { it.isLoading() }) Visibility.visible else Visibility.hidden
            }
        }
        allStates.forEach {
            if (it is ResponseState.Error) {
                Alert {
                    +it.exception.toString()
                    severity = AlertColor.error
                }
            }
        }
    }
}