package views

import common.reactRouter
import components.requireAuth
import csstype.WhiteSpace
import emotion.react.css
import externals.AdapterDateFnsModule
import externals.FallbackComponentProps
import externals.LocalizationProviderModule.LocalizationProvider
import externals.NbLocaleModule
import externals.ReactErrorBoundaryModule.ErrorBoundary
import history.createBrowserHistory
import kotlinx.js.jso
import mui.material.Alert
import mui.material.AlertColor
import mui.material.Container
import mui.material.CssBaseline
import mui.material.styles.ThemeProvider
import mui.material.styles.createTheme
import react.FC
import react.Props
import react.create
import react.dom.html.ReactHTML.h2
import react.router.Route
import react.router.Routes
import react.router.dom.HistoryRouter
import views.email.EmailItemListView
import views.header.Header
import views.login.LoginView

private object Theme {
    val default = createTheme(options = jso {
        reactRouter()
    })
}

private val browserHistory = createBrowserHistory()
fun navigate(to: String, replace: Boolean = false) {
    if (replace) browserHistory.replace(to) else browserHistory.push(to)
}

val AppView = FC<Props>("AppView") {
    LocalizationProvider {
        dateAdapter = AdapterDateFnsModule.AdapterDateFns
        adapterLocale = NbLocaleModule.nbLocale
        ThemeProvider {
            theme = Theme.default
            CssBaseline()
            AppRoutes()
        }
    }
}

// TODO: ErrorBoundary at each route?
private val AppRoutes = FC<Props>("AppRoutes") {
    Header {}
    Container {
        maxWidth = "md"
        ErrorBoundary {
            FallbackComponent = ErrorDisplay
            HistoryRouter {
                history = browserHistory
                Routes {
                    Route {
                        index = true
                        element = requireAuth(Index)
                    }
                    Route {
                        path = Nav.operation
                        element = requireAuth(OperationView)
                    }
                    Route {
                        path = Nav.operationCreate
                        element = requireAuth(CreateOperationStepper)
                    }
                    Route {
                        path = Nav.email
                        element = requireAuth(EmailItemListView)
                    }
                    Route {
                        path = Nav.openingHours
                        element = requireAuth(OpeningHoursView)
                    }

                    // Can be used to manually reset login.
                    Route {
                        path = Nav.login
                        element = LoginView.create()
                    }
                }
            }
        }
    }
}

private val ErrorDisplay = FC<FallbackComponentProps> {
    h2 { +"Oops, Something is Broken..." }
    Alert {
        css { whiteSpace = WhiteSpace.preWrap }
        +it.error.stackTraceToString()
        severity = AlertColor.error
    }
}