package validations

class ValidationException(override val message: String) : Exception(message)

sealed class ValidationResult {
    data class Fail(val message: String) : ValidationResult()
    object Ok : ValidationResult()

    fun failOrNull() = this as? Fail
    fun okOrNull() = this as? Ok
}

object Validation {
    @Throws(ValidationException::class)
    fun <T> validateList(
        items: Iterable<T>,
        header: String = "One or more invalid items: ",
        op: (T) -> Unit
    ) {
        val res = items.map { runCatching { op(it) } }
        if (res.all { it.isSuccess }) return
        val message = res.mapIndexedNotNull { index, result ->
            result.exceptionOrNull()?.let { index to it }
        }.joinToString("\n", header) { (index, e) ->
            "${index}='${e.message}'"
        }
        throw ValidationException(message)
    }
}