package views.operation

import components.LastUpdatedView
import components.editor.MyEditor
import data.ResponseState
import data.isLoading
import helpers.OpeningHoursHelper
import model.OperationItem
import model.OperationServices
import model.operation.toJs
import mui.lab.LoadingButton
import mui.material.ButtonVariant
import mui.material.Stack
import mui.system.responsive
import react.FC
import react.Props
import react.useMemo

external interface OperationItemProps : Props {
    var item: OperationItem
    var openingHours: OpeningHoursHelper?
    var stateDelete: ResponseState<*>
    var onDelete: (OperationItem) -> Unit
}

val OperationItemView = FC<OperationItemProps> { props ->
    val timeJs = useMemo(props.item.time) { props.item.time.toJs() }
    val serviceResults = useMemo(props.item) {
        OperationServices.values().map { it.getItemValue(props.item) }.toTypedArray()
    }
    Stack {
        spacing = responsive(2)
        OperationTimePicker {
            value = timeJs
            openingHours = props.openingHours
            readOnlyStart = true
            readOnlyEnd = true
        }
        MyEditor {
            value = props.item.messageNor
            readOnly = true
        }
        MyEditor {
            value = props.item.messageEng
            readOnly = true
        }
        ServiceResult {
            services = OperationServices.values()
            values = serviceResults
        }
        props.item.weather?.let {
            OperationWeatherView { weatherData = it }
        }
        LastUpdatedView {
            createdTime = props.item.createdTime
            lastUpdatedName = props.item.lastUpdatedName
            lastUpdatedTime = props.item.lastUpdatedTime
        }
        LoadingButton {
            loading = props.stateDelete.isLoading()
            variant = ButtonVariant.contained
            onClick = { props.onDelete(props.item) }
            +"Delete"
        }
    }
}