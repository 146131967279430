package model

import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable

@Serializable
data class LocalTime(
    val hour: Int,
    val minute: Int
) : Comparable<LocalTime> {
    companion object {
        val ZERO = LocalTime(0, 0)
        val MAX = LocalTime(23, 59)
        private fun padTime(value: Int) = if (value < 10) "0$value" else value.toString()
        fun fromMinutes(minutes: Int) = LocalTime(minutes / 60, minutes % 60)
    }

    override fun toString() = "${padTime(hour)}:${padTime(minute)}"
    fun toMinutes() = hour * 60 + minute
    override fun compareTo(other: LocalTime) = toMinutes() - other.toMinutes()
}

fun String.toLocalTime() = LocalTime(
    hour = substring(0, 2).toInt(),
    minute = substring(3, 5).toInt()
)

val LocalDateTime.localTime: LocalTime
    get() = LocalTime(hour, minute)

fun LocalTime.toLocalDateTime(date: LocalDate) =
    LocalDateTime(date.year, date.monthNumber, date.dayOfMonth, hour, minute)

fun LocalDate.toLocalDateTime(time: LocalTime) =
    LocalDateTime(year, monthNumber, dayOfMonth, time.hour, time.minute)
