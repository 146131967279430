package model

import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.polymorphic
import kotlinx.serialization.modules.subclass

@Serializable
data class CreateOperationRequest(
    val time: OperationTime,
    val messageNor: String,
    val messageEng: String,
    val services: OperationServicesRequest,
    val weather: WeatherData?
)

@Serializable
data class OperationTime(
    val start: OperationTimeStart,
    val end: OperationTimeEnd
)

interface OperationTimeBase : Comparable<OperationTimeBase> {
    fun asDateTime(): LocalDateTime
    override fun compareTo(other: OperationTimeBase): Int = asDateTime().compareTo(asDateTime())
}

@Serializable
sealed class OperationTimeStart : OperationTimeBase {
    @Serializable
    data class Date(val date: LocalDate) : OperationTimeStart() {
        override fun asDateTime() = date.toLocalDateTime(LocalTime.ZERO)
    }

    @Serializable
    data class DateTime(val dateTime: LocalDateTime) : OperationTimeStart() {
        override fun asDateTime() = dateTime
    }
}

@Serializable
sealed class OperationTimeEnd : OperationTimeBase {
    @Serializable
    data class Date(val date: LocalDate) : OperationTimeEnd()

    @Serializable
    data class DateTime(val dateTime: LocalDateTime) : OperationTimeEnd()

    @Serializable
    object Undecided : OperationTimeEnd()

    override fun asDateTime() = when (this) {
        is Date -> date.toLocalDateTime(LocalTime.MAX)
        is DateTime -> dateTime
        is Undecided -> LocalDateTime(3000, 1, 1, 0, 0)
    }
}

@Serializable
data class OperationServicesRequest(
    val workplace: Boolean,
    val website: Boolean,
    val displays: Boolean,
    val emailPartners: Boolean,
    val google: Boolean,
    val facebook: Boolean
)

@Serializable
data class WeatherData(
    val meteorologist: CheckData,
    val yr: CheckData,
    val weatherStationInternal: CheckData,
    val weatherStationExternal: CheckData,
    val certain: CheckData
)

@Serializable
data class CheckData(
    val checked: Boolean,
    val note: String
) {
    companion object {
        val Init = CheckData(false, "")
    }
}

@Serializable
data class OperationItem(
    override val itemId: String,
    val messageNor: String,
    val messageEng: String,
    val createdTime: Instant,
    val time: OperationTime,
    override val deleted: Boolean,
    val lastUpdatedName: String,
    val lastUpdatedTime: Instant,
    val workplace: OperationServiceResult<ServiceData.Workplace>,
    val website: OperationServiceResult<ServiceData.None>,
    val displays: OperationServiceResult<ServiceData.None>,
    val emailPartners: OperationServiceResult<ServiceData.Email>,
    val google: OperationServiceResult<ServiceData.None>,
    val facebook: OperationServiceResult<ServiceData.None>,
    val weather: WeatherData?
) : IdItem {
    fun serviceResults() = listOf(workplace, website, displays, emailPartners, google, facebook)
}

@Serializable
sealed class OperationServiceResult<out T : ServiceData> {
    @Serializable
    @SerialName("Ok")
    data class Ok<out T : ServiceData>(val data: T) : OperationServiceResult<T>()

    @Serializable
    @SerialName("Off")
    object Off : OperationServiceResult<@Contextual Nothing>()

    @Serializable
    @SerialName("Pending")
    object Pending : OperationServiceResult<@Contextual Nothing>()

    @Serializable
    @SerialName("Failed")
    data class Failed(val details: String) : OperationServiceResult<@Contextual Nothing>()
}

@Serializable
sealed class ServiceData {
    @Serializable
    data class Workplace(val postId: String) : ServiceData()

    @Serializable
    data class Email(
        val messageIdNor: String?,
        val messageIdEng: String?
    ) : ServiceData()

    @Serializable
    object None : ServiceData()
}

val operationResultsModel = SerializersModule {
    polymorphic(ServiceData::class) {
        subclass(ServiceData.Workplace.serializer())
        subclass(ServiceData.Email.serializer())
        subclass(ServiceData.None.serializer())
    }
}
