package views.operation

import common.MaterialIcon
import csstype.FontWeight
import csstype.WhiteSpace
import emotion.react.css
import model.WeatherData
import mui.material.*
import react.FC
import react.Props
import react.create
import react.dom.html.ReactHTML.span

external interface OperationWeatherProps : Props {
    var weatherData: WeatherData?
}

val OperationWeatherView = FC<OperationWeatherProps> {
    val data = it.weatherData ?: return@FC
    Accordion {
        AccordionSummary {
            expandIcon = MaterialIcon.create { name = "expand_more" }
            Typography { +"Weather Info" }
        }
        AccordionDetails {
            Stack {
                css { whiteSpace = WhiteSpace.preLine }
                listOf(
                    "Checked with meteorologist?" to data.meteorologist,
                    "Checked with short-term YR forecast?" to data.yr,
                    "Checked with our own weather station?" to data.weatherStationInternal,
                    "Checked with external weather station?" to data.weatherStationExternal,
                    "Certain?" to data.certain
                ).forEach { (header, data) ->
                    Stack {
                        span {
                            css { fontWeight = FontWeight.bold }
                            +"$header ${if (data.checked) "☑" else "☐"}"
                        }
                        data.note.takeUnless { it.isBlank() }?.let {
                            span {
                                +data.note
                            }
                        }
                    }
                }
            }
        }
    }
}