package utils

import kotlinx.datetime.*
import model.operation.Localized

val timeZone = TimeZone.of("Europe/Oslo")
fun Clock.System.nowLocal() = now().toLocalDateTime(timeZone)
fun Clock.System.today() = todayIn(timeZone)
fun Instant.toLocal() = toLocalDateTime(timeZone)
fun Instant.toDate() = toLocalDateTime(timeZone).date
fun LocalDateTime.toInstant() = toInstant(timeZone)

fun twoDigit(value: Int) = if (value < 10) "0$value" else value.toString()

fun DayOfWeek.nor() = when (this) {
    DayOfWeek.MONDAY -> "mandag"
    DayOfWeek.TUESDAY -> "tirsdag"
    DayOfWeek.WEDNESDAY -> "onsdag"
    DayOfWeek.THURSDAY -> "torsdag"
    DayOfWeek.FRIDAY -> "fredag"
    DayOfWeek.SATURDAY -> "lørdag"
    DayOfWeek.SUNDAY -> "søndag"
    else -> this.name
}

fun DayOfWeek.eng() = this.name.lowercase().replaceFirstChar { it.uppercase() }

val monthFormattedEng = Month.values().associateWith { month ->
    val clipped = month.name.substring(0, 3)
    clipped.lowercase().replaceFirstChar { it.uppercase() }
}

val monthFormattedNor = Month.values().associateWith { month ->
    when (month) {
        Month.JANUARY -> "jan."
        Month.FEBRUARY -> "feb."
        Month.MARCH -> "mars"
        Month.APRIL -> "apr."
        Month.MAY -> "mai"
        Month.JUNE -> "juni"
        Month.JULY -> "juli"
        Month.AUGUST -> "aug."
        Month.SEPTEMBER -> "sep."
        Month.OCTOBER -> "okt."
        Month.NOVEMBER -> "nov."
        Month.DECEMBER -> "des."
        else -> TODO()
    }
}

fun Month.eng() = monthFormattedEng.getValue(this)
fun Month.nor() = monthFormattedNor.getValue(this)

fun LocalDate.formatEng(format: DateFormat) = format.eng(this)
fun LocalDateTime.formatEng(format: DateTimeFormat) = format.eng(this)

class DateFormat(private val eng: LocalDate.() -> String, private val nor: LocalDate.() -> String) {
    companion object {
        fun join(vararg formats: DateFormat) = DateFormat(
            { formats.joinToString(", ") { it.eng(this) } },
            { formats.joinToString(", ") { it.nor(this) } }
        )

        val TODAY = DateFormat({ "today" }, { "i dag" })
        val YESTERDAY = DateFormat({ "yesterday" }, { "i går" })
        val TOMORROW = DateFormat({ "tomorrow" }, { "i morgen" })
        val WEEKDAY = DateFormat({ dayOfWeek.eng() }, { dayOfWeek.nor() })
        val WEEKDAY_SHORT = DateFormat(
            { dayOfWeek.eng().substring(0, 3) },
            { dayOfWeek.nor().substring(0, 3) }
        )
        val DAY_MONTH = DateFormat(
            { "$dayOfMonth ${month.eng()}" },
            { "${dayOfMonth}. ${month.nor()}" }
        )
        val DAY_MONTH_YEAR = DateFormat(
            { "${DAY_MONTH.eng(this)} $year" },
            { "${DAY_MONTH.nor(this)} $year" }
        )
        val DAY_MONTH_SHORT_YEAR = DateFormat(
            { "${DAY_MONTH.eng(this)} ’${year.toString().substring(2)}" },
            { "${DAY_MONTH.nor(this)} ’${year.toString().substring(2)}" }
        )
    }

    fun eng(date: LocalDate) = date.eng()
    fun nor(date: LocalDate) = date.nor()
}

data class DateTimeFormat(val dateFormat: DateFormat? = null, val format24: Boolean = false) {
    companion object {
        val ONLY_TIME = DateTimeFormat(null)
        fun is24(dateTime: LocalDateTime) = dateTime.hour == 0 && dateTime.minute == 0
        fun time(dateTime: LocalDateTime, format24: Boolean = false) =
            if (format24 && is24(dateTime)) "24:00" else "${twoDigit(dateTime.hour)}:${twoDigit(dateTime.minute)}"
    }

    fun time(dateTime: LocalDateTime) = Companion.time(dateTime, format24)

    fun eng(dateTime: LocalDateTime) =
        dateFormat?.let { "${it.eng(dateTime.date)}, ${time(dateTime)}" } ?: time(dateTime)

    fun nor(dateTime: LocalDateTime) =
        dateFormat?.let { "${it.nor(dateTime.date)}, ${time(dateTime)}" } ?: time(dateTime)
}

class DateFormatter(val base: LocalDate, val timeZone: TimeZone) {
    constructor(base: LocalDateTime, timeZone: TimeZone) : this(base.date, timeZone)
    constructor(base: Instant, timeZone: TimeZone) : this(base.toLocalDateTime(timeZone), timeZone)

    fun get(value: Instant) = get(value.toLocalDateTime(timeZone))
    fun get(value: LocalDateTime) = get(value.date)
    fun get(value: LocalDate): DateFormatted {
        val dateDiff = base.daysUntil(value)
        val format = when {
            dateDiff == 0 -> DateFormat.TODAY
            dateDiff == -1 -> DateFormat.YESTERDAY
            dateDiff == 1 -> DateFormat.TOMORROW
            base.year != value.year -> DateFormat.DAY_MONTH_SHORT_YEAR
            else -> DateFormat.join(DateFormat.WEEKDAY_SHORT, DateFormat.DAY_MONTH)
        }
        return DateFormatted(value, format)
    }
}

class DateTimeFormatter(private val dateFormatter: DateFormatter) {
    fun get(value: Instant) = get(value.toLocalDateTime(timeZone))
    fun get(value: LocalDateTime): DateTimeFormatted {
        val valueDate = value.date
        val format = if (DateTimeFormat.is24(value)) {
            DateTimeFormat(dateFormatter.get(valueDate.minus(DateTimeUnit.DAY)).format, true)
        } else {
            DateTimeFormat(dateFormatter.get(valueDate).format, false)
        }
        return DateTimeFormatted(value, format)
    }
}

interface DateTimeFormattedBase : Localized

data class DateFormatted(
    private val value: LocalDate,
    val format: DateFormat
) : DateTimeFormattedBase {
    override val eng by lazy { format.eng(value) }
    override val nor by lazy { format.nor(value) }
}

data class DateTimeFormatted(
    private val value: LocalDateTime,
    private val format: DateTimeFormat
) : DateTimeFormattedBase {
    override val eng by lazy { format.eng(value) }
    override val nor by lazy { format.nor(value) }
}
