package common

import mui.material.BaseTextFieldProps
import mui.material.StandardTextFieldProps
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import react.ReactNode
import react.dom.events.ChangeEventHandler

@Suppress("NOTHING_TO_INLINE")
inline fun String.asLabel() = this.unsafeCast<ReactNode>()
inline var BaseTextFieldProps.onChange: ChangeEventHandler<HTMLInputElement>?
    get() = this.unsafeCast<StandardTextFieldProps>().onChange
    set(value) {
        this.unsafeCast<StandardTextFieldProps>().onChange = value.unsafeCast<ChangeEventHandler<HTMLElement>>()
    }
