package components.time

import common.MaterialIcon
import csstype.px
import csstype.rgba
import emotion.react.css
import kotlinx.js.jso
import mui.material.*
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLSelectElement
import react.*
import react.dom.events.ChangeEventHandler
import react.dom.html.ReactHTML.option
import react.dom.html.SelectHTMLAttributes
import utils.important
import utils.number

data class TimePickerBaseOption(val value: String, val display: String)

external interface TimePickerBaseStyleProps : Props {
    var hideIcon: Boolean?
    var disabled: Boolean?
    var readOnly: Boolean?
    var size: BaseSize?
}

external interface TimePickerBaseProps : TimePickerBaseStyleProps {
    var value: String?
    var options: List<List<TimePickerBaseOption>>?
    var onChange: ((String) -> Unit)?
}

val TimePickerBase = FC<TimePickerBaseProps> { props ->
    val hideIcon = props.hideIcon ?: false
    val disabled = props.disabled ?: false
    val readOnly = props.readOnly ?: false

    val onChange: ChangeEventHandler<HTMLInputElement> = useCallback(props.onChange) {
        props.onChange?.invoke(it.target.value)
    }

    val value = props.value ?: "HH:MM"
    val options = props.options?.takeUnless { it.isEmpty() } ?: listOf(listOf(TimePickerBaseOption(value, value)))

    FormControl {
        this.disabled = disabled
        this.size = props.size
        NativeSelect {
            this.value = value
            this.onChange = onChange.unsafeCast<ChangeEventHandler<*>>()
            this.input = OutlinedInput.create()

            // Props to select element
            this.inputProps = jso<SelectHTMLAttributes<HTMLSelectElement>> {
                this.disabled = disabled || readOnly
                css {
                    paddingRight = 8.5.px.important()
                    if (readOnly && !disabled) {
                        opacity = 1.0.number().important()
                        asDynamic()["WebkitTextFillColor"] = rgba(0, 0, 0, 0.87).important()
                    }
                }
            }
            this.IconComponent = {
                InputAdornment.create {
                    position = InputAdornmentPosition.start
                    if (!hideIcon) {
                        MaterialIcon {
                            this.name = "schedule"
                        }
                    }
                }
            }.unsafeCast<ElementType<Props>>()
            options.forEachIndexed { index, subOptions ->
                if (index > 0) {
                    option {
                        this.value = "──"
                        this.disabled = true
                        +"──"
                    }
                }
                subOptions.forEach {
                    option {
                        this.value = it.value
                        +it.display
                    }
                }
            }
        }
    }
}