package components

import csstype.*
import emotion.react.css
import model.IdItem
import react.ChildrenBuilder
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.tbody
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.thead
import react.dom.html.ReactHTML.tr
import styles.outlineBorder
import utils.css
import utils.useDesktop

external interface MyTableProps : Props {
    var data: Collection<IdItem>
    var selectedId: String?
    var onSelected: (IdItem) -> Unit
    var headerCreator: (ChildrenBuilder) -> Unit
    var rowCreator: (ChildrenBuilder, index: Int, item: IdItem) -> Unit
    var emptyMessage: String?
    var wordBreak: Boolean?
}

val MyTable = FC<MyTableProps>("MyTable") { props ->
    val desktop = useDesktop()
    table {
        css {
            width = 100.pct
            textAlign = TextAlign.center
            borderRadius = 4.px
            border = outlineBorder
            fontSize = if (desktop) 0.875.rem else 0.8.rem
            letterSpacing = 0.01071.em
            lineHeight = 1.43.css()
            borderSpacing = 2.px
            marginBottom = 20.px
            if (props.wordBreak == true) wordBreak = WordBreak.breakWord

            "tr" {
                borderRadius = 4.px
            }
            "th" {
                fontWeight = 500.css()
                height = 56.px
                borderBottom = Border(1.px, LineStyle.solid, rgb(224, 224, 224))
            }
            "td" {
                fontWeight = 400.css()
                height = 38.px
                padding = 2.px
            }
            "td > *" {
                verticalAlign = VerticalAlign.middle
            }

        }
        thead {
            tr {
                props.headerCreator(this)
            }
        }
        tbody {
            props.data.forEachIndexed { index, item ->
                tr {
                    css {
                        if (item.deleted) {
                            opacity = 0.5.css()
                        }
                        if (item.itemId == props.selectedId) {
                            outline = "1px solid #42a5f5".css()
                        }
                    }
                    props.rowCreator(this, index, item)
                    onClick = { props.onSelected(item) }
                }
            }
            if (props.data.isEmpty()) {
                tr {
                    td {
                        css { fontStyle = FontStyle.italic }
                        colSpan = "100%".css()
                        +(props.emptyMessage ?: "No rows")
                    }
                }
            }
        }
    }
}