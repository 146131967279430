package views.openinghours

import model.OpeningDay
import model.OpeningHoursItemDetails
import model.OpeningWeek
import react.Reducer
import views.OpeningHoursPatchItem
import kotlin.reflect.KProperty1

class OpeningHoursLocation(
    val displayName: String,
    val prop: KProperty1<OpeningHoursItemDetails, OpeningWeek>,
    val copy: OpeningHoursItemDetails.(OpeningWeek) -> OpeningHoursItemDetails
)

class OpeningHoursWeekday(
    val displayName: String,
    val prop: KProperty1<OpeningWeek, OpeningDay>,
    val copy: OpeningWeek.(OpeningDay) -> OpeningWeek
)

val locationValues = listOf(
    OpeningHoursLocation("Fjellheisen", OpeningHoursItemDetails::fjellheisen) { copy(fjellheisen = it) },
    OpeningHoursLocation("Panorama", OpeningHoursItemDetails::fjellheisenPanorama) { copy(fjellheisenPanorama = it) },
    OpeningHoursLocation("Fjellstua", OpeningHoursItemDetails::fjellstua) { copy(fjellstua = it) },
)
val weekdayValues = listOf(
    OpeningHoursWeekday("M", OpeningWeek::monday) { copy(monday = it) },
    OpeningHoursWeekday("T", OpeningWeek::tuesday) { copy(tuesday = it) },
    OpeningHoursWeekday("W", OpeningWeek::wednesday) { copy(wednesday = it) },
    OpeningHoursWeekday("T", OpeningWeek::thursday) { copy(thursday = it) },
    OpeningHoursWeekday("F", OpeningWeek::friday) { copy(friday = it) },
    OpeningHoursWeekday("S", OpeningWeek::saturday) { copy(saturday = it) },
    OpeningHoursWeekday("S", OpeningWeek::sunday) { copy(sunday = it) },
)

typealias OpeningHoursMap = Map<String, OpeningHoursPatchItem>

sealed interface OpeningHoursAction {
    data class Update(val data: OpeningHoursMap) : OpeningHoursAction
    data class NewItem(val data: OpeningHoursPatchItem) : OpeningHoursAction
    data class UpdateItem(val itemId: String, val update: (OpeningHoursPatchItem) -> OpeningHoursPatchItem) :
        OpeningHoursAction
}

val OpeningHoursDataReducer: Reducer<OpeningHoursMap, OpeningHoursAction> = { state, action ->
    when (action) {
        is OpeningHoursAction.Update -> action.data
        is OpeningHoursAction.NewItem -> state.plus(action.data.itemId to action.data)
        is OpeningHoursAction.UpdateItem -> state[action.itemId]?.let {
            state.plus(action.itemId to action.update(it))
        } ?: state
    }
}