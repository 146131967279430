package views.email

import common.Icon
import common.IconType
import common.onChange
import common.asLabel
import components.LastUpdatedView
import csstype.AlignItems
import csstype.Display
import csstype.JustifyContent
import emotion.react.css
import model.EmailItemDetails
import mui.material.*
import mui.system.responsive
import react.FC
import react.Props
import react.create
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h4
import utils.validationResult

external interface EmailItemViewProps : Props {
    var item: EmailPatchItem
    var onChange: (EmailPatchItem) -> Unit
}

val EmailItemView = FC<EmailItemViewProps> { props ->
    val update = { details: EmailItemDetails ->
        props.onChange(props.item.copy(details = details))
    }
    div {
        css {
            display = Display.flex
            justifyContent = JustifyContent.spaceBetween
        }
        h4 { +"Item" }
        IconButton {
            Icon("delete", if (props.item.deleted) IconType.FILLED else IconType.OUTLINED)
            onClick = { props.onChange(props.item.copy(deleted = !props.item.deleted)) }
        }
    }
    Stack {
        spacing = responsive(1)
        direction = responsive(StackDirection.column)
        css {
            alignItems = AlignItems.center
        }

        TextField {
            value = props.item.details.name
            fullWidth = true
            onChange = {
                update(props.item.details.copy(name = it.target.value))
            }
            validationResult = props.item.nameValid
        }
        TextField {
            value = props.item.details.email
            fullWidth = true
            onChange = {
                update(props.item.details.copy(email = it.target.value))
            }
            validationResult = props.item.emailValid
        }
        FormControlLabel {
            label = "Norwegian".asLabel()
            labelPlacement = LabelPlacement.start
            control = Checkbox.create {
                checked = props.item.details.norwegian
                onChange = { _, checked ->
                    update(props.item.details.copy(norwegian = checked))
                }
            }
        }
        LastUpdatedView {
            lastUpdatedName = props.item.lastUpdatedName
            lastUpdatedTime = props.item.lastUpdatedTime
        }
    }
}