package common

import csstype.ClassName
import csstype.Color
import csstype.Opacity
import emotion.react.css
import react.ChildrenBuilder
import react.FC
import react.Props
import react.dom.html.ReactHTML.span

data class Icon(val name: String, val type: IconType = IconType.OUTLINED)

class IconType(val className: String) {
    companion object {
        val FILLED = IconType("material-icons")
        val OUTLINED = IconType("material-icons-outlined")
        val ROUNDED = IconType("material-icons-round")
    }
}

external interface MaterialIconProps : Props {
    var name: String?
    var type: IconType?
    var color: Color?
}

val MaterialIcon = FC<MaterialIconProps> {
    span {
        css(ClassName((it.type ?: IconType.FILLED).className)) {
            if (it.name == null) opacity = 0.0.unsafeCast<Opacity>()
            it.color?.let { color = it }
        }
        +(it.name ?: "warning")
    }
}

@Suppress("FunctionName")
fun ChildrenBuilder.Icon(name: String?, type: IconType? = null) {
    MaterialIcon {
        this.name = name
        this.type = type
    }
}