package components

import common.MaterialIcon
import mui.material.Button
import mui.material.Menu
import mui.material.MenuItem
import org.w3c.dom.Element
import org.w3c.dom.HTMLElement
import react.*
import react.dom.html.ReactHTML.div

/**
 * Inspired by https://mui.com/material-ui/react-button-group/
 */

external interface ButtonDropdownProps : Props {
    var name: String?
    var options: List<String>?
    var onClick: ((Int) -> Unit)?
}

val ButtonDropdown = FC<ButtonDropdownProps> { props ->
    val options = props.options ?: emptyList()
    var anchorElement by useState<HTMLElement>()

    div {
        Button {
            onClick = { anchorElement = it.currentTarget }
            endIcon = MaterialIcon.create {
                name = "arrow_drop_down"
            }
            +(props.name ?: "")
        }
        Menu {
            anchorEl = anchorElement.unsafeCast<(Element) -> Element>()
            open = anchorElement != null
            onClose = { anchorElement = null }
            options.forEachIndexed { idx, name ->
                MenuItem {
                    onClick = { props.onClick?.invoke(idx) }
                    +name
                }
            }
        }
    }
}