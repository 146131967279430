package common

import io.ktor.client.plugins.*
import kotlinx.js.Object
import react.dom.events.FormEvent
import react.dom.events.FormEventHandler

fun Throwable.format() = when (this) {
    is ClientRequestException -> "${response.status.description}: ${
        toString().split("Text:").getOrNull(1) ?: "Unknown"
    }"

    else -> toString()
}

fun formEventHandler(block: (FormEvent<*>) -> Unit): FormEventHandler<*> = {
    it.preventDefault()
    block(it)
}

fun <T> Any.overrideFunction(name: String, block: (old: T) -> T) {
    val old = asDynamic()[name].unsafeCast<T>()
    asDynamic()[name] = block(old)
}

fun <T : Any> T.diff(other: T) = Object.getOwnPropertyNames(this).mapNotNull {
    val v1 = asDynamic()[it]
    val v2 = other.asDynamic()[it]
    if (v1 != v2) it to "$v1 != $v2" else null
}.associate { it }

fun <T : Any> shallowEqual(a: T, b: T) =
    Object.getOwnPropertyNames(a).all { a.asDynamic()[it] === b.asDynamic()[it] }