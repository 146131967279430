package common

import kotlinx.js.Object
import kotlinx.js.jso
import mui.material.styles.ThemeOptions
import react.PropsWithRef
import react.createElement
import react.rawForwardRef
import react.router.dom.Link
import react.router.dom.LinkProps

val LinkBehavior = rawForwardRef<Any, PropsWithRef<Any>> { props, ref ->
    val linkProps = jso<LinkProps> {
        this.ref = ref
        this.to = props.asDynamic()["href"] as String
    }
    Object.getOwnPropertyNames(props)
        .filter { it !in setOf("href", "ref") }
        .forEach { linkProps.asDynamic()[it] = props.asDynamic()[it] }
    createElement(Link, linkProps)
}

fun ThemeOptions.reactRouter() {
    components = jso {
        this["MuiLink"] = jso {
            this["defaultProps"] = jso {
                this["component"] = LinkBehavior
            }
        }
        this["MuiButtonBase"] = jso {
            this["defaultProps"] = jso {
                this["LinkComponent"] = LinkBehavior
            }
        }
    }
}