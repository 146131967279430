package components.editor

import externals.SlateModule
import externals.SlateNode
import externals.SlateTextNode
import helpers.PlaceholderConverter
import helpers.PlaceholderMapping

object EditorConverter {
    /**
     * Transform slate data to a template string.
     * Nodes is an array of paragraph nodes, which contains children inline nodes.
     **/
    fun fromSlateNodes(nodes: Array<SlateNode>): String =
        nodes.joinToString("\n") {
            it.children.mapNotNull {
                when {
                    SlateModule.Text.isText(it) -> it.unsafeCast<SlateTextNode>().text
                    it.type == elementTypePlaceholder -> it.unsafeCast<SlatePlaceholderNode>().placeholderType.key
                    else -> null
                }
            }.joinToString("")
        }

    /**
     * Transform a template string to slate data.
     * Returns an array of paragraph nodes, which contains children inline nodes.
     **/
    fun toSlateNodes(value: String, placeholderMapping: PlaceholderMapping?) =
        value.split('\n').map {
            ParagraphNode(children = parseInlineNodes(it, placeholderMapping))
        }.toTypedArray()

    private fun parseInlineNodes(value: String, placeholderMapping: PlaceholderMapping?): Array<SlateNode> =
        buildList {
            PlaceholderConverter.resolveInternal(value, placeholderMapping,
                { add(StyledTextNode(it)) },
                { type, value -> add(PlaceholderNode(type, value)) }
            )
        }.toTypedArray()
}
