package validations

import model.EmailItem
import model.EmailItemDetails

object EmailValidation {

    private val emailRegex =
        Regex("""^[a-zA-Z0-9.!#${'$'}%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*${'$'}""")

    fun validateEmail(s: String): ValidationResult {
        if(!emailRegex.matches(s)) return ValidationResult.Fail("Invalid email.")
        return ValidationResult.Ok
    }

    fun validateName(s: String): ValidationResult {
        if (s.length < 3) return ValidationResult.Fail("Name must have at least 3 letters.")
        return ValidationResult.Ok
    }

    fun validateItem(item: EmailItemDetails): ValidationResult {
        validateEmail(item.email).failOrNull()?.let { return it }
        validateName(item.name).failOrNull()?.let { return it }
        return ValidationResult.Ok
    }
}