package views.operationcreate

import components.editor.MyEditor
import helpers.OpeningHoursHelper
import helpers.OperationTimeHelpers
import kotlinx.datetime.Clock
import kotlinx.js.jso
import model.Language
import model.OperationServices
import model.operation.OperationTimeInfo
import model.operation.OperationTimeJs
import mu.KotlinLogging
import mui.material.FormLabel
import mui.material.Stack
import mui.system.responsive
import react.FC
import react.Props
import react.useMemo
import styles.OutlinedDiv
import utils.EmailContentCreator
import utils.nowLocal

external interface OperationContentEditorProps : Props {
    var valueNor: String
    var onValueNorChanged: (String) -> Unit
    var valueEng: String
    var onValueEngChanged: (String) -> Unit
    var time: OperationTimeJs
    var templates: List<Template>
    var openingHours: OpeningHoursHelper
    var services: Array<Boolean>
}

private val logger = KotlinLogging.logger("OperationContentEditor")
val OperationContentEditor = FC<OperationContentEditorProps> { props ->

    /*val baseTime = useMemo { Clock.System.now().toLocalDateTime(timeZone).date }
    val (placeholderMappingEng, placeholderMappingNor) = useMemo(props.time, props.openingHours) {
        PlaceholderContentHelper.getPlaceholders(baseTime, props.time, props.openingHours)
    }*/

    val timeInfo = useMemo(props.time, props.openingHours) {
        OperationTimeHelpers.timeInfo(props.time, props.openingHours, Clock.System.nowLocal())
    }
    Stack {
        spacing = responsive(2)
        OperationReport {
            this.timeInfo = timeInfo
        }
        TemplateSelection {
            templates = props.templates
            contentEng = props.valueEng
            contentNor = props.valueNor
            onClick = {
                props.onValueNorChanged(it.contentNor)
                props.onValueEngChanged(it.contentEng)
            }
        }
        MyEditor {
            value = props.valueNor
            onChange = props.onValueNorChanged
            placeholder = "Norwegian Message"
            //placeholderMapping = placeholderMappingNor
            //toolbarDefinition = toolbarDefinitionDefault
        }
        MyEditor {
            value = props.valueEng
            onChange = props.onValueEngChanged
            placeholder = "English Message"
            //placeholderMapping = placeholderMappingEng
            //toolbarDefinition = toolbarDefinitionDefault
        }
        if (OperationServices.EMAIL_PARTNERS.getSelectValue(props.services)) {
            OperationEmailPreview {
                this.timeInfo = timeInfo
                this.message = props.valueEng
            }
        }
    }
}

external interface EmailPreviewProps : Props {
    var timeInfo: OperationTimeInfo
    var message: String
}

val OperationEmailPreview = FC<EmailPreviewProps> {
    val html = useMemo(it.timeInfo, it.message) {
        EmailContentCreator.contentOperationNew(it.message, it.timeInfo, Language.ENG).htmlBody()
    }
    Stack {
        spacing = responsive(0.5)
        FormLabel { +"Email Preview" }
        OutlinedDiv { dangerouslySetInnerHTML = jso { __html = html } }
    }
}
