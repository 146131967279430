package common

import kotlinx.browser.document

fun getCookie(name: String) = document.cookie.split(';')
    .mapNotNull {
        it.split("=", limit = 2).takeIf { it.size == 2 }?.let {
            it[0].trimCookiePart() to it[1].trimCookiePart()
        }
    }.find { it.first == name }?.second

private fun String.trimCookiePart() = trim(' ', '\t')

fun deleteCookie(name: String) {
    document.cookie = "$name=;expires=Thu, 01 Jan 1970 00:00:00 GMT"
}
