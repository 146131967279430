package model

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class EmailItem(
    val details: EmailItemDetails,
    val itemId: String,
    val lastUpdatedName: String,
    val lastUpdatedTime: Instant,
    val deleted: Boolean
)

@Serializable
data class EmailItemDetails(
    val name: String,
    val email: String,
    val norwegian: Boolean
)

@Serializable
data class EmailListResponse(
    val items: List<EmailItem>
)

@Serializable
data class EmailPatch(
    val created: List<EmailItemDetails>,
    val updated: Map<String, EmailItemDetails>,
    val deleted: Set<String>
)
