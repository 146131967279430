package utils

import kotlinx.html.*
import kotlinx.html.stream.createHTML

/**
 * Guide: https://webdesign.tutsplus.com/articles/creating-a-simple-responsive-html-email--webdesign-12978
 */

class Dimension(private val dimension: Int) {
    override fun toString() = "${dimension}px"
    operator fun times(i: Int): Dimension = Dimension(this.dimension * i)
}

class CssBuilder {
    var value = ""
    operator fun String.unaryPlus() = run { value += if (last() == ';') this else "$this;" }
}

fun CommonAttributeGroupFacade.css(block: CssBuilder.() -> Unit) =
    run { style = attributes.getOrElse("style") { "" } + CssBuilder().apply(block).value }

fun emailHtml(block: BODY.() -> Unit) = createHTML().html {
    head {
        meta(charset = "utf-8")
        meta(name = "viewport", content = "width=device-width,initial-scale=1")
        meta(name = "x-apple-disable-message-reformatting")
    }
    body { myBody(block) }
}

fun emailBody(block: BODY.() -> Unit) = createHTML().body { myBody(block) }

private fun BODY.myBody(block: BODY.() -> Unit) {
    css {
        +"margin:0"
        +"padding:0"
        +"word-spacing:normal"
    }
    block()
}


/*
private val LINE_START_PATTERN = Regex("(?m)^")

private fun quotePlainText(prevMessage: String?, newMessage: String) =
    prevMessage?.let {
        "${LINE_START_PATTERN.replace(prevMessage, "> ")}\n$newMessage"
    } ?: newMessage

private fun quoteHtml(prevHtml: String?, newHtml: String) = email {
    blockQuote {
        css {
            +"color: gray"
            +"border-left: 1px solid #4f4f4f"
            +"padding-left: 16px"
        }
        prevHtml?.let { unsafe { raw(it) } }
    }
    unsafe { raw(newHtml) }
}*/
