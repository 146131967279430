package views

import components.updates.FetchAndUpdateHeader
import csstype.px
import data.*
import emotion.react.css
import framework.invoke
import helpers.OpeningHoursHelper
import kotlinx.datetime.Clock
import model.OperationItem
import mui.material.Button
import mui.material.ButtonVariant
import react.FC
import react.Props
import react.dom.html.ReactHTML.h3
import react.useMemo
import react.useState
import utils.nowLocal
import utils.useMainScope
import utils.value
import views.header.Footer
import views.operation.OperationItemView
import views.operation.OperationTable

val OperationView = FC<Props> {
    val scope = useMainScope()
    val now = useMemo { Clock.System.nowLocal() }
    val stateOperationGet = useResponseState<Unit>()
    val stateOperationDelete = useResponseState<Unit>()
    val stateOpeningGet = useResponseState<OpeningHoursHelper>()
    var selected by useState<OperationItem>()
    var itemsMap by useState<Map<String, OperationItem>>(emptyMap())

    scope.useLoadOnce(stateOpeningGet) {
        val openingHours = backend.openingHours.get()
        OpeningHoursHelper(openingHours, minTime = null)
    }
    scope.useLoadOnce(stateOperationGet) {
        val data = backend.operation.get()
            .filter { !it.deleted && now < it.time.end.asDateTime() }
            .sortedBy { it.time.start }
        selected = data.firstOrNull()
        itemsMap = data.associateBy { it.itemId }
    }
    FetchAndUpdateHeader {
        this.heading = "Operation Messages"
        this.fetchStates = listOf(stateOperationGet.value, stateOpeningGet.value)
        this.updateState = ResponseState.None
    }
    Button {
        variant = ButtonVariant.contained
        fullWidth = true
        href = Nav.operationCreate
        +"Create New Message"
    }
    h3 {
        css { paddingTop = 16.px }
        +"Current Messages"
    }
    OperationTable {
        this.data = itemsMap.values
        this.selectedId = selected?.itemId
        this.onSelection = { selected = it }
    }
    selected?.let { item ->
        h3 {
            css { paddingTop = 16.px }
            +"Details"
        }
        OperationItemView {
            this.item = item
            this.openingHours = stateOpeningGet.value.success()
            this.stateDelete = stateOperationDelete.value
            this.onDelete = { item ->
                scope.load(stateOperationDelete) {
                    val deletedItem = backend.operation.item(item.itemId).delete()
                    itemsMap.minus(deletedItem.itemId).let {
                        itemsMap = it
                        selected = it.values.firstOrNull()
                    }
                }
            }
        }
    }
    Footer {}
}