package helpers

import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDateTime
import utils.nowLocal
import utils.toInstant
import kotlin.time.Duration.Companion.minutes

object TimePickerHelper {
    fun now(minuteStep: Int): Instant {
        val local = Clock.System.nowLocal().let {
            LocalDateTime(it.year, it.monthNumber, it.dayOfMonth, it.hour, it.minute)
        }
        return round(local, minuteStep)
    }

    private fun round(dateTime: LocalDateTime, minuteStep: Int): Instant {
        val extraMinutes = minuteStep - (dateTime.minute % minuteStep)
        return dateTime.toInstant() + extraMinutes.minutes
    }
}