package views.header

import csstype.Globals
import csstype.number
import csstype.px
import data.logoutUser
import data.useUser
import emotion.react.css
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import org.w3c.dom.HTMLButtonElement
import react.FC
import react.Props
import react.dom.events.MouseEventHandler
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import utils.css
import views.navigate

val Header = FC<Props>("Header") {
    val user = useUser()
    val onClickHome: MouseEventHandler<HTMLButtonElement> = { navigate("/") }
    val onClickLogout: MouseEventHandler<HTMLButtonElement> = { logoutUser() }

    AppBar {
        css { marginBottom = 10.px }
        position = AppBarPosition.static
        Container {
            maxWidth = "md"
            Toolbar {
                variant = ToolbarVariant.dense
                IconButton {
                    sx { marginRight = 2.px }
                    edge = IconButtonEdge.start
                    size = Size.large
                    color = IconButtonColor.inherit
                    onClick = onClickHome
                    img {
                        css {
                            maxWidth = 24.px
                            maxHeight = 24.px
                            filter = "invert(100%)".css()
                        }
                        src = "/logo-symbol-mini.png"
                    }
                }
                Typography {
                    sx { flexGrow = number(1.0) }
                    variant = TypographyVariant.h6
                    component = div
                    +"Fjellheisen Portal"
                }
                if (user != null) {
                    Button {
                        +"Logout"
                        sx { color = Globals.inherit }
                        onClick = onClickLogout
                    }
                }
            }
        }
    }
}
