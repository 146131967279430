package views.openinghours

import components.time.TimePickerBase
import components.time.TimePickerBaseStyleProps
import helpers.OperationTimeHelpers
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.minus
import model.DateSelectableTime
import react.FC
import react.useMemo
import utils.DateFormatter
import utils.DateTimeFormat
import utils.timeZone

external interface OpeningTimeViewProps : TimePickerBaseStyleProps {
    var selectableTime: DateSelectableTime?
    var start: Boolean?
}

val OpeningTimeView = FC<OpeningTimeViewProps> { props ->
    val formatter = useMemo { DateFormatter(OperationTimeHelpers.nowTimeLocal(), timeZone) }
    val start = props.start ?: return@FC
    val selectableTime = props.selectableTime ?: return@FC

    val value = selectableTime.openingHours?.let {
        if (start) it.start else it.endInclusive
    }?.let {
        val (date, timeString) = when {
            DateTimeFormat.is24(it) -> it.date.minus(DateTimeUnit.DAY) to "24:00"
            else -> it.date to it.time.toString()
        }
        when (date) {
            selectableTime.date -> timeString
            else -> "$timeString (${formatter.get(date).engCap()})"
        }
    } ?: "Closed"
    TimePickerBase {
        +props
        this.value = value
        disabled = true
    }
}


external interface OpeningNowTimeProps : TimePickerBaseStyleProps {
    var now: LocalDateTime?
}

val OpeningTimeNowView = FC<OpeningNowTimeProps> { props ->
    val now = props.now ?: return@FC
    TimePickerBase {
        +props
        this.value = now.time.toString()
        disabled = true
    }
}