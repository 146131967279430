package components.editor

import csstype.*
import emotion.react.css
import externals.SlateHistoryModule.withHistory
import externals.SlateModule.createEditor
import externals.SlateNode
import externals.SlateReactModule.Editable
import externals.SlateReactModule.Slate
import externals.SlateReactModule.withReact
import externals.SlateRenderProps
import mu.KotlinLogging
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.useCallback
import react.useMemo
import helpers.PlaceholderMapping
import styles.outlineBorder

/***
 * Known bug: If writing out startSub or endSub, this will fail (likely due to setting children directly).
 */

private val logger = KotlinLogging.logger("Editor")

external interface EditorProps : Props {
    var value: String
    var onChange: ((String) -> Unit)?
    var placeholder: String?
    var disabled: Boolean?
    var readOnly: Boolean?
    var placeholderMapping: PlaceholderMapping?
    var toolbarDefinition: List<ToolbarButton>?
}

val MyEditor = FC<EditorProps> { props ->
    /** Memo **/
    val editor = useMemo { withReact(withPlaceholders(withHistory(createEditor()))) }
    val editorValue = useMemo(props.value, props.placeholderMapping) {
        EditorConverter.toSlateNodes(props.value, props.placeholderMapping)
    }
    val onChange: (Array<SlateNode>) -> Unit = useCallback(props.onChange) {
        props.onChange?.invoke(EditorConverter.fromSlateNodes(it))
    }
    val renderElement = useMemo {
        FC<SlateRenderProps<*>> {
            when (it.element.type) {
                elementTypePlaceholder -> PlaceholderElement { +it }
                else -> ParagraphElement { +it }
            }
        }
    }
    val renderLeaf = useMemo { FC<SlateRenderProps<*>> { Leaf { +it } } }

    /** End memo **/
    div {
        css {
            border = outlineBorder
            borderRadius = 4.px
            padding = 10.px
            opacity = (if (props.disabled == true) 0.8 else 1.0).unsafeCast<Opacity>()
        }
        editor.children = editorValue  // Setting values after initial value
        Slate {
            this.editor = editor
            this.value = editorValue // Setting initial value
            this.onChange = onChange
            EditorToolbar {
                placeholderMapping = props.placeholderMapping
                toolbarDefinition = props.toolbarDefinition
            }
            Editable {
                this.placeholder = props.placeholder
                this.renderElement = renderElement
                this.renderLeaf = renderLeaf
                this.readOnly = (props.readOnly == true) || (props.disabled == true)
                //onKeyDown = { event -> onKeyDown(event, editor) }
            }
        }
    }
}
