package views.operation

import components.MyTable
import csstype.Color
import csstype.Overflow
import csstype.rem
import emotion.css.ClassName
import emotion.react.css
import kotlinx.datetime.Clock
import model.*
import model.OperationItem
import model.OperationServiceResult
import model.OperationTimeEnd
import model.OperationTimeStart
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.th
import react.useMemo
import utils.DateFormatter
import utils.DateTimeFormatter
import utils.timeZone

external interface OperationTableProps : Props {
    var data: Collection<OperationItem>
    var selectedId: String?
    var onSelection: (OperationItem) -> Unit
}

val OperationTable = FC<OperationTableProps> { props ->
    val dateFormatter = useMemo { DateFormatter(Clock.System.now(), timeZone) }
    val dateTimeFormatter = DateTimeFormatter(dateFormatter)

    MyTable {
        data = props.data
        selectedId = props.selectedId
        onSelected = props.onSelection.unsafeCast<(IdItem) -> Unit>()
        headerCreator = { builder ->
            builder.apply {
                th { +"Start" }
                th { +"End" }
                th { +"Message" }
            }
        }
        rowCreator = { builder, _, item ->
            item as OperationItem

            val start = when(item.time.start) {
                is OperationTimeStart.Date -> dateFormatter.get(item.time.start.date)
                is OperationTimeStart.DateTime -> dateTimeFormatter.get(item.time.start.dateTime)
            }.engCap()
            val end = when(item.time.end) {
                is OperationTimeEnd.Date -> dateFormatter.get(item.time.end.date).engCap()
                is OperationTimeEnd.DateTime -> dateTimeFormatter.get(item.time.end.dateTime).engCap()
                is OperationTimeEnd.Undecided -> "---"
            }
            builder.apply {
                val errorTextStyle = ClassName {
                    if (item.serviceResults().any { it is OperationServiceResult.Failed }) {
                        color = Color("#c62828")
                    }
                }
                td { css(errorTextStyle) {}; +start }
                td { css(errorTextStyle) {}; +end }
                td {
                    div {
                        css(errorTextStyle) {
                            maxHeight = 2.6.rem
                            overflow = Overflow.hidden
                        }
                        +item.messageEng
                    }
                }
            }
        }
    }
}