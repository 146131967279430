package views.email

import components.updates.FetchAndUpdateHeader
import components.updates.UpdateToolbar
import data.*
import framework.invoke
import kotlinx.datetime.Instant
import model.*
import randomId
import react.FC
import react.Props
import react.useState
import utils.*
import validations.EmailValidation
import validations.ValidationResult

data class EmailPatchItem(
    override val itemId: String,
    override val details: EmailItemDetails,
    override val deleted: Boolean,
    override val lastUpdatedName: String?,
    override val lastUpdatedTime: Instant?,
    override val oldItem: EmailPatchItem?,
) : PatchItem<EmailItemDetails> {
    val nameValid = EmailValidation.validateName(details.name)
    val emailValid = EmailValidation.validateEmail(details.email)
    override val valid = nameValid is ValidationResult.Ok && emailValid is ValidationResult.Ok
}

typealias EmailMap = Map<String, EmailPatchItem>

fun EmailItem.toPatchItem() = EmailPatchItem(
    details = details,
    itemId = itemId,
    deleted = deleted,
    lastUpdatedName = lastUpdatedName,
    lastUpdatedTime = lastUpdatedTime,
    oldItem = EmailPatchItem(
        details = details,
        itemId = itemId,
        deleted = deleted,
        lastUpdatedName = lastUpdatedName,
        lastUpdatedTime = lastUpdatedTime,
        oldItem = null
    )
)

fun createEmailPatch(data: EmailMap) = createPatchGeneric(data).let {
    EmailPatch(it.created, it.updated, it.deleted)
}

val EmailItemListView = FC<Props> {
    val scope = useMainScope()
    val fetchState = useResponseState<EmailMap>()
    val updateState = useResponseState<Unit>()
    var data by useState<EmailMap>(emptyMap())
    var selected by useState<String>()

    scope.useLoadOnce(fetchState) {
        backend.email.get().items
            .map { it.toPatchItem() }
            .sortedBy { it.lastUpdatedTime }
            .also { selected = it.getOrNull(0)?.itemId }
            .associateBy { it.itemId }
            .also { data = it }
    }
    FetchAndUpdateHeader {
        this.heading = "Emails"
        this.fetchStates = listOf(fetchState.value)
        this.updateMessage = "Emails updated"
        this.updateState = updateState.value
        this.updateStateReset = { updateState(it) }
    }

    val originalData = fetchState.value.success() ?: return@FC
    val dataList = data.values.toList()
        .filter { !it.deleted || it.changeStatus == ChangeStatus.UPDATED_DELETED }
        .sortedBy { it.details.name }

    UpdateToolbar {
        this.error = dataList.any { !it.valid }
        this.changes = dataList.any { it.changeStatus != ChangeStatus.EQUAL }
        this.onAdd = {
            val new = EmailPatchItem(
                details = EmailItemDetails("", "", true),
                itemId = randomId(),
                lastUpdatedTime = null,
                lastUpdatedName = null,
                deleted = false,
                oldItem = null
            )
            data = data.plus(new.itemId to new)
            selected = new.itemId
        }
        this.onSave = {
            scope.load(updateState) {
                val newItems = backend.email.patch(createEmailPatch(data)).items
                    .map { it.toPatchItem() }
                    .associateBy { it.itemId }
                val newData = (originalData + newItems)
                fetchState.set(ResponseState.Success(newData))
                data = newData
            }
        }
        this.onDiscard = {
            data = originalData
            updateState.set(ResponseState.None)
        }
    }
    EmailTable {
        this.data = dataList
        this.selectedId = selected
        this.onSelected = { selected = it.itemId }
    }
    selected?.let { data[it] }?.let { selectedItem ->
        EmailItemView {
            this.item = selectedItem
            this.onChange = {
                data = data.plus(it.itemId to it)
            }
        }
    }
}
