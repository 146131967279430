package helpers

import kotlinx.datetime.*
import model.*
import model.LocalTime
import utils.rangeTo
import utils.toDate

class OpeningHoursHelper(
    openingHours: List<OpeningHoursItem>,
    private val minTime: LocalDateTime?,
    private val location: Location = Location.FJELLHEISEN,
    private val cacheEnabled: Boolean = true
) {
    companion object {
        private const val allowAllTimes = true
        private const val nextValidSearchDays = 30 * 6
        private val lastTime = LocalTime.fromMinutes(24 * 60 - OperationTimeHelpers.pickerMinuteStep)

        private operator fun OpeningWeek.get(key: DayOfWeek) = when (key) {
            DayOfWeek.MONDAY -> monday
            DayOfWeek.TUESDAY -> tuesday
            DayOfWeek.WEDNESDAY -> wednesday
            DayOfWeek.THURSDAY -> thursday
            DayOfWeek.FRIDAY -> friday
            DayOfWeek.SATURDAY -> saturday
            DayOfWeek.SUNDAY -> sunday
            else -> throw Exception("Unknown week: $key")
        }

        private fun findSelectableTime(
            date: LocalDate,
            openingDatePrev: ClosedRange<LocalDateTime>?,
            openingDate: ClosedRange<LocalDateTime>?,
            minTime: LocalDateTime?
        ): DateSelectableTime {
            // Find current day range:
            val selectableDate: ClosedRange<LocalTime>? = openingDate?.let {
                val dayEnd = if (openingDate.endInclusive.date > date) lastTime else openingDate.endInclusive.localTime
                openingDate.start.localTime..dayEnd
            }

            // Find previous day range (if any)
            val selectablePrevDate: ClosedRange<LocalTime>? = openingDatePrev?.let {
                if (openingDatePrev.endInclusive.date == date) {
                    LocalTime.ZERO..openingDatePrev.endInclusive.localTime
                } else null
            }

            // Assemble
            val selectable = listOfNotNull(selectablePrevDate, selectableDate)

            // Filter on now date
            val selectableFiltered = if (minTime?.date == date) {
                val minTodayTime = minTime.localTime
                selectable.mapNotNull {
                    if (it.endInclusive <= minTodayTime) null
                    else it.start.coerceAtLeast(minTodayTime)..it.endInclusive
                }
            } else {
                selectable
            }
            return DateSelectableTime(date = date, openingHours = openingDate, selectableTime = selectableFiltered)
        }
    }

    enum class Location(val getter: (OpeningHoursItemDetails) -> OpeningWeek) {
        FJELLHEISEN({ it.fjellheisen }), PANORAMA({ it.fjellheisenPanorama }), FJELLSTUA({ it.fjellstua })
    }

    private val openingHoursValid = openingHours.filter { !it.deleted }
    private val cacheOpeningHours = mutableMapOf<LocalDate, ClosedRange<LocalDateTime>?>().takeIf { cacheEnabled }
    private val cacheSelectableTime = mutableMapOf<LocalDate, DateSelectableTime>().takeIf { cacheEnabled }

    private inline fun <K, V> MutableMap<K, V>?.getCache(key: K, defaultValue: () -> V): V =
        this?.let { getOrPut(key, defaultValue) } ?: defaultValue()


    fun getOpeningHours(date: LocalDate) = cacheOpeningHours.getCache(date) { findOpeningHours(date) }

    /**
     * Finds the opening hours for the date.
     * Gives null when its closed or a valid opening hours record can't be found (should not happen).
     */
    private fun findOpeningHours(date: LocalDate): ClosedRange<LocalDateTime>? {
        val matchingDate = openingHoursValid.findLast { date >= it.details.startDate }
            ?: openingHoursValid.firstOrNull() ?: return null
        val opening = location.getter(matchingDate.details)[date.dayOfWeek]
        if (opening.start == opening.end) return null
        val endDate = if (opening.end < opening.start) date.plus(DateTimeUnit.DAY) else date
        return opening.start.toLocalDateTime(date)..opening.end.toLocalDateTime(endDate)
    }

    fun getSelectableTime(date: LocalDate) = cacheSelectableTime.getCache(date) {
        val currentOpeningHours = getOpeningHours(date)
        if (allowAllTimes) {
            DateSelectableTime(
                date = date,
                openingHours = currentOpeningHours,
                selectableTime = listOf(LocalTime.ZERO..lastTime)
            )
        } else {
            val prevOpeningHours = getOpeningHours(date.minus(DateTimeUnit.DAY))
            findSelectableTime(
                date = date,
                openingDatePrev = prevOpeningHours,
                openingDate = currentOpeningHours,
                minTime = minTime
            )
        }
    }

    fun nextValidTimeRange(start: Instant): ClosedRange<Instant> {
        val startDate = start.toDate()
        val selectableToday = getSelectableTime(startDate).selectableInstant

        // Check if start is in a range:
        selectableToday.find { start in it }?.let { return start..it.endInclusive }

        // Check if there is a range after start, pick the first one:
        selectableToday.find { start < it.start }?.let { return it }

        // Search for next valid
        (1..nextValidSearchDays).forEach { dateOffset ->
            getSelectableTime(startDate.plus(dateOffset, DateTimeUnit.DAY)).selectableInstant.getOrNull(0)
                ?.let { return it }
        }
        throw Exception("Could not find any valid time range in the next $nextValidSearchDays days.")
    }

    fun getNextOpeningHours(from: LocalDate): ClosedRange<LocalDateTime> {
        repeat(nextValidSearchDays) {
            getOpeningHours(from.plus(it, DateTimeUnit.DAY))?.let { return it }
        }
        throw Exception("Could not find opening hours in the next $nextValidSearchDays days.")
    }


}