package model

import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable

@Serializable
data class OpeningHoursItem(
    val details: OpeningHoursItemDetails,
    val itemId: String,
    val lastUpdatedName: String,
    val lastUpdatedTime: Instant,
    val deleted: Boolean
)

@Serializable
data class OpeningHoursItemDetails(
    val startDate: LocalDate,
    val fjellheisen: OpeningWeek,
    val fjellheisenPanorama: OpeningWeek,
    val fjellstua: OpeningWeek
)

@Serializable
data class OpeningWeek(
    val monday: OpeningDay,
    val tuesday: OpeningDay,
    val wednesday: OpeningDay,
    val thursday: OpeningDay,
    val friday: OpeningDay,
    val saturday: OpeningDay,
    val sunday: OpeningDay,
)

@Serializable
data class OpeningDay(
    val start: LocalTime,
    val end: LocalTime
) {
    companion object {
        val CLOSED = OpeningDay(LocalTime(0, 0), LocalTime(0, 0))
        val DEFAULT = OpeningDay(LocalTime(9, 0), LocalTime(23, 0))
    }
}

@Serializable
data class OpeningHoursPatch(
    val created: List<OpeningHoursItemDetails>,
    val updated: Map<String, OpeningHoursItemDetails>,
    val deleted: Set<String>
)

@Serializable
data class OpeningHoursListResponseExternal(
    val records: List<OpeningHoursItemDetails>
)

@Serializable
data class OpeningHoursRangeResponse(
    val startDate: LocalDate,
    val endDate: LocalDate,
    val dates: List<OpeningHoursRangeDate>
)

@Serializable
data class OpeningHoursRangeDate(
    val date: LocalDate,
    val fjellheisen: OpeningHoursRangeDateRange?,
    val fjellheisenPanorama: OpeningHoursRangeDateRange?,
    val fjellstua: OpeningHoursRangeDateRange?
)

@Serializable
data class OpeningHoursRangeDateRange(
    val start: Instant,
    val end: Instant
)