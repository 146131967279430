package utils

import csstype.ClassName
import csstype.GridColumn
import csstype.GridRow
import csstype.PropertiesBuilder
import kotlinx.js.jso
import react.dom.html.HTMLAttributes

fun PropertiesBuilder.grid(row: Int, column: Int) {
    gridRow = row.unsafeCast<GridColumn>()
    gridColumn = column.unsafeCast<GridRow>()
}

fun HTMLAttributes<*>.grid(row: Int, column: Int) {
    style = jso {
        gridRow = row.unsafeCast<GridColumn>()
        gridColumn = column.unsafeCast<GridRow>()
    }
}


inline fun className(
    crossinline block: PropertiesBuilder.() -> Unit,
): ClassName = emotion.css.css(jso(block))