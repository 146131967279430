package styles

import csstype.*
import emotion.react.css
import emotion.styled.styled
import react.PropsWithClassName
import react.dom.html.ReactHTML

object OptionStyles {
    val selectedColor = rgba(25, 118, 210, 0.08)

    val OptionGroup = ReactHTML.div.styled { _, _ ->
        display = Display.flex
        flexDirection = FlexDirection.column

        ".MuiOutlinedInput-root" {
            backgroundColor = Color("#fff")
        }
    }
    val Option = ReactHTML.div.styled { _, _ ->
        display = Display.flex
        alignItems = AlignItems.stretch
        border = outlineBorder
        firstOfType {
            borderBottomWidth = 0.px
            borderTopLeftRadius = 5.px
            borderTopRightRadius = 5.px
        }
        lastOfType {
            borderBottomWidth = 1.px
            borderBottomLeftRadius = 5.px
            borderBottomRightRadius = 5.px
        }
    }
    val NestedOption = ReactHTML.div.styled { _, _ ->
        borderLeft = Border(0.px, LineStyle.solid, rgba(0, 0, 0, 0.10))  // Removed when selected
        borderTop = outlineBorder
        firstOfType {
            borderTopWidth = 0.px
        }
    }
}

fun PropertiesBuilder.selected(cond: Boolean) {
    if (cond) {
        color = rgb(25, 118, 210)
        backgroundColor = OptionStyles.selectedColor
    } else {
        borderLeftWidth = 1.px  // Needed for border with NestedOption
    }
}

fun PropsWithClassName.selected(cond: Boolean) = css { selected(cond) }