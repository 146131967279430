package components.updates

import csstype.*
import data.ResponseState
import data.isLoading
import emotion.react.css
import mui.material.Alert
import mui.material.AlertColor
import mui.material.LinearProgress
import react.FC
import react.Props
import react.dom.html.ReactHTML.div

external interface ResponseStateViewProps : Props {
    var state: ResponseState<*>
    var alertSuccess: Boolean?
    var progressAlwaysUseSpace: Boolean?
}

val ResponseStateView = FC<ResponseStateViewProps> { props ->
    val alertSuccess = props.alertSuccess ?: false
    val progressAlwaysUseSpace = props.progressAlwaysUseSpace ?: false

    div {
        css {
            display = Display.flex
            flexDirection = FlexDirection.column
            alignItems = AlignItems.stretch
            "> *" {
                margin = 10.px
            }
        }
        if (progressAlwaysUseSpace) {
            LinearProgress {
                css { visibility = if (props.state.isLoading()) Visibility.visible else Visibility.hidden }
            }
        } else if (props.state.isLoading()) {
            LinearProgress { }
        }

        when (val state = props.state) {
            is ResponseState.None -> let {}
            is ResponseState.Loading -> {}
            is ResponseState.Error -> {
                Alert {
                    +state.exception.toString()
                    severity = AlertColor.error
                }
            }
            is ResponseState.Success -> {
                if (alertSuccess) {
                    Alert {
                        +"Success!"
                        severity = AlertColor.success
                    }
                }
            }
        }
    }
}
