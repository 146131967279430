package views.operationcreate

import csstype.px
import emotion.react.css
import mui.material.Alert
import mui.material.AlertColor
import mui.material.Typography
import mui.material.styles.TypographyVariant
import react.FC
import react.Props
import styles.OptionStyles.Option
import styles.OptionStyles.OptionGroup
import styles.selected

external interface OperationSendTimePickerProps : Props

val OperationSendTimePicker = FC<OperationSendTimePickerProps> {
    OptionGroup {
        Option {
            selected(true)
            Typography {
                css { padding = 10.px }
                variant = TypographyVariant.overline
                +"Send Now"
            }
        }
    }
    Alert {
        css { marginTop = 20.px }
        severity = AlertColor.info
        +"More options will come later"
    }
}