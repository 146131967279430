package views.operationcreate

import csstype.Display
import csstype.FlexDirection
import csstype.FontWeight
import csstype.px
import emotion.react.css
import model.operation.OperationTimeInfo
import mui.material.Paper
import react.FC
import react.Props
import react.dom.html.ReactHTML
import utils.css

external interface OperationTimeViewProps : Props {
    var timeInfo: OperationTimeInfo?
}

val OperationReport = FC<OperationTimeViewProps> {
    Paper {
        css {
            display = Display.flex
            padding = 10.px
            "> div" {
                display = Display.flex
                flexDirection = FlexDirection.column
            }
        }
        val info = it.timeInfo ?: return@Paper
        ReactHTML.div {
            css { marginRight = 10.px; fontWeight = FontWeight.bold }
            ReactHTML.span { +"From:" }
            ReactHTML.span {
                css { opacity = 0.5.css() }
                +"To:"
            }
            ReactHTML.span { +"Open:" }
        }
        ReactHTML.div {
            ReactHTML.span { +info.start.engCap() }
            ReactHTML.span {
                css { opacity = 0.5.css() }
                +info.end.engCap()
            }
            ReactHTML.span { +info.open.engCap() }
        }
    }
}

