package framework

import camelToSpinalCase
import kotlin.properties.ReadOnlyProperty
import kotlin.reflect.KFunction0
import kotlin.reflect.KProperty

/** Route **/

@DslMarker
annotation class RouteMarker

@RouteMarker
sealed class RouteBase {
    abstract val parent: RouteBase?
    abstract val context: RouteContext
    abstract val nodePath: String
    abstract val fullPath: String

    protected fun <T : Route> route(path: String, creator: () -> T) =
        creator().also { it.data = RouteData(this, path) }

    protected fun <T : Route> route(creator: () -> T, spinalCase: Boolean = true) =
        ReadOnlyProperty<RouteBase, T> { _, property ->
            route(
                path = if (spinalCase) property.name.camelToSpinalCase() else property.name,
                creator = creator
            )
        }

    // Shorthand for route delegate
    protected operator fun <T : Route> KFunction0<T>.getValue(thisRef: RouteBase, property: KProperty<*>) =
        route(property.name.camelToSpinalCase(), this)

    @Deprecated("Avoid specifying parameter name explicitly. Use delegate.")
    protected fun <T : Route> parameter(parameterName: String, creator: () -> T) =
        RouteParameter(parameterName) { parameterPath ->
            creator().also { it.data = RouteData(this@RouteBase, parameterPath) }
        }

    @Suppress("DEPRECATION")
    protected fun <T : Route> parameter(creator: () -> T) =
        ReadOnlyProperty<RouteBase, RouteParameter<T>> { _, property -> parameter(property.name, creator) }

    protected fun <R : Any> getMethod(path: String? = null) = Endpoint.Get<R>(EndpointData(this, path))
    protected fun headMethod(path: String? = null) = Endpoint.Head(EndpointData(this, path))
    protected fun <T, R : Any> postMethod(path: String? = null) = Endpoint.Post<T, R>(EndpointData(this, path))
    protected fun <T> putMethod(path: String? = null) = Endpoint.Put<T>(EndpointData(this, path))
    protected fun <T : Any> deleteMethod(path: String? = null) = Endpoint.Delete<T>(EndpointData(this, path))
    protected fun <T, R : Any> patchMethod(path: String? = null) = Endpoint.Patch<T, R>(EndpointData(this, path))

    override fun hashCode() = fullPath.hashCode()
    override fun equals(other: Any?): Boolean = when (other) {
        is RouteBase -> fullPath == other.fullPath
        else -> super.equals(other)
    }
}

open class Route : RouteBase() {
    lateinit var data: RouteData
    final override val parent: RouteBase
        get() = data.parent
    final override val context: RouteContext
        get() = data.parent.context
    final override val nodePath: String
        get() = data.path
    final override val fullPath: String
        get() = joinPath(data.parent, nodePath)
}

open class Api(final override val fullPath: String = "") : RouteBase() {
    private lateinit var contextInternal: RouteContext
    final override val nodePath = fullPath
    final override val parent: RouteBase? = null
    final override val context by ::contextInternal

    fun initialize(context: RouteContext) = run { this.contextInternal = context }
}

@RouteMarker
@Suppress("unused")
sealed class Endpoint(private val data: EndpointData) {
    val context by data.parent::context
    val path: String
        get() = joinPath(data.parent, data.path)

    class Get<R>(data: EndpointData) : Endpoint(data)
    class Head(data: EndpointData) : Endpoint(data)
    class Post<T, R>(data: EndpointData) : Endpoint(data)
    class Put<T>(data: EndpointData) : Endpoint(data)
    class Delete<T>(data: EndpointData) : Endpoint(data)
    class Patch<T, R>(data: EndpointData) : Endpoint(data)
}

class RouteParameter<T : Route>(val name: String, val creator: (path: String) -> T)
class RouteData(val parent: RouteBase, val path: String)
class EndpointData(val parent: RouteBase, val path: String?)
open class RouteContext {
    open fun newContext(): RouteContext = RouteContext()
}

fun joinPath(parent: RouteBase, b: String?) = buildList {
    b?.let { add(it); add("/") }
    var p: RouteBase? = parent
    while (p != null) {
        if (p.nodePath != "") {
            add(p.nodePath)
            add("/")
        }
        p = p.parent
    }
}.reversed().let {
    buildString { it.forEach { append(it) } }
}

