package views.login

import common.formEventHandler
import common.format
import common.onChange
import common.asLabel
import csstype.AlignItems
import csstype.Display
import csstype.rem
import data.ResponseState
import data.error
import data.isLoading
import kotlinx.js.jso
import model.LoginInitResponse
import mui.material.*
import mui.system.sx
import react.FC
import react.Props
import react.create
import react.dom.html.ButtonType
import react.dom.html.InputType
import react.dom.html.ReactHTML

external interface LoginPhoneViewProps : Props {
    var state: ResponseState<LoginInitResponse>
    var area: String
    var number: String
    var onChangeArea: (String) -> Unit
    var onChangeNumber: (String) -> Unit
    var onSubmit: () -> Unit
}

val LoginPhoneView = FC<LoginPhoneViewProps> { props ->
    props.state.error()?.let {
        ReactHTML.p { +it.exception.format() }
    }
    ReactHTML.form {
        onSubmit = formEventHandler { props.onSubmit() }
        Box {
            sx {
                display = Display.flex
                alignItems = AlignItems.center
            }
            TextField {
                sx { width = 5.rem }
                label = "Prefix".asLabel()
                key = "area"
                value = props.area
                placeholder = "47"
                onChange = { props.onChangeArea(it.target.value) }
                disabled = props.state.isLoading()
                unsafeCast<OutlinedTextFieldProps>().apply {
                    InputProps = jso {
                        startAdornment = InputAdornment.create {
                            position = InputAdornmentPosition.start
                            +"+"
                        }
                    }
                }
            }
            TextField {
                key = "phone"
                label = "Phone".asLabel()
                autoFocus = true
                type = InputType.tel
                placeholder = "9008070"
                disabled = props.state.isLoading()
                onChange = { props.onChangeNumber(it.target.value) }
                value = props.number
            }
        }
        Button {
            +"Submit"
            sx { marginTop = 1.rem }
            variant = ButtonVariant.contained
            type = ButtonType.submit
            fullWidth = true
            disabled = props.state.isLoading() || props.area.isEmpty() || props.number.isEmpty()
        }
    }
}
