package views.email

import components.MyTablePatch
import csstype.Display
import emotion.css.css
import emotion.react.css
import emotion.styled.styled
import model.PatchItem
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.th
import react.useCallback

external interface EmailTableProps : Props {
    var data: List<EmailPatchItem>
    var selectedId: String?
    var onSelected: (EmailPatchItem) -> Unit
}

private val spanInlineBlock = span.styled { _, _ ->
    display = Display.inlineBlock
}

val EmailTable = FC<EmailTableProps> { props ->
    MyTablePatch {
        data = props.data
        selectedId = props.selectedId
        onSelected = props.onSelected.unsafeCast<(PatchItem<*>) -> Unit>()
        wordBreak = true
        headerCreator = useCallback { builder ->
            builder.apply {
                th { +"Name" }
                th { +"Email" }
                th { +"Language" }
            }
        }
        rowCreator = useCallback { builder, _, itemRaw ->
            val item = itemRaw.unsafeCast<EmailPatchItem>()
            builder.apply {
                td { +item.details.name }
                td {
                    val s = item.details.email.split("@", limit = 2)
                    spanInlineBlock { +s[0] }
                    s.getOrNull(1)?.let { spanInlineBlock { +"@${it}" } }
                }
                td { +(if (item.details.norwegian) "NOR" else "ENG") }
            }
        }
    }
}