package components.editor

import csstype.*
import emotion.react.css
import externals.SlateNode
import externals.SlateRenderProps
import kotlinx.js.jso
import react.FC
import react.dom.html.ReactHTML.p
import utils.css

const val paragraphNodeType = "paragraph"

val ParagraphElement = FC<SlateRenderProps<*>> {
    p {
        setSlateProps(it)
        css {
            margin = Margin(0.px, 0.px)
            ":not(:last-child):after" {
                content = "'↩'".css()
                color = Color("#999")
                verticalAlign = VerticalAlign.middle
            }
        }
    }
}

@Suppress("FunctionName")
fun ParagraphNode(vararg children: SlateNode) = jso<SlateNode> {
    type = paragraphNodeType
    this.children = children
}
