package components.updates

import common.MaterialIcon
import csstype.*
import emotion.react.css
import mui.material.Button
import mui.material.ButtonGroup
import mui.material.ButtonGroupVariant
import mui.material.ButtonVariant
import react.FC
import react.Props
import react.create
import react.dom.html.ReactHTML.div
import styles.outlineBorder

external interface UpdateToolbarProps : Props {
    var changes: Boolean?
    var error: Boolean?
    var onAdd: (() -> Unit)?
    var onSave: (() -> Unit)?
    var onDiscard: (() -> Unit)?
}

val UpdateToolbar = FC<UpdateToolbarProps> { props ->
    val changes = props.changes ?: false
    val error = props.error ?: false
    div {
        css {
            display = Display.flex
            justifyContent = JustifyContent.spaceBetween
            borderRadius = 4.px
            border = outlineBorder
            padding = 1.px
        }
        Button {
            +"Add"
            variant = ButtonVariant.outlined
            endIcon = MaterialIcon.create {
                name = "add_circle"
            }
            onClick = { props.onAdd?.invoke() }
        }
        ButtonGroup {
            variant = ButtonGroupVariant.outlined
            Button {
                +"Save"
                disabled = !changes || error
                endIcon = MaterialIcon.create {
                    name = "cloud_sync"
                }
                onClick = { props.onSave?.invoke() }
            }
            Button {
                +"Cancel"
                disabled = !changes
                endIcon = MaterialIcon.create {
                    name = "clear"
                }
                onClick = { props.onDiscard?.invoke() }
            }
        }
    }
}