package components.time

import csstype.rem
import externals.DatePickerModule.DatePicker
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.datetime.toJSDate
import kotlinx.datetime.toKotlinInstant
import mui.material.BaseSize
import mui.material.TextField
import mui.system.sx
import react.*
import utils.DateFormatter
import utils.timeZone
import kotlin.js.Date

external interface MyDatePickerProps : Props {
    var label: String?
    var value: Instant
    var onChange: (Instant) -> Unit
    var minTime: Instant?
    var maxTime: Instant?
    var shouldDisableDate: ((Instant) -> Boolean)?
    var disabled: Boolean?
    var readOnly: Boolean?
    var size: BaseSize?
}

val MyDatePicker = FC<MyDatePickerProps>("MyDatePicker") { props ->
    val valueFormatter = useMemo { DateFormatter(Clock.System.now(), timeZone) }
    val onChange: (Date) -> Unit = useCallback(props.onChange) {
        props.onChange(it.toKotlinInstant())
    }
    val shouldDisableDate: ((Date) -> Boolean)? = useMemo(props.shouldDisableDate) {
        props.shouldDisableDate?.let { { date: Date -> it(date.toKotlinInstant()) } }
    }
    DatePicker {
        label = props.label
        value = props.value.toJSDate()
        this.onChange = onChange
        this.renderInput = {
            TextField.create {
                +it
                sx { "input" { width = 5.8.rem } }
                inputProps?.asDynamic().readOnly = true
                inputProps?.asDynamic().value = valueFormatter.get(props.value).engCap()
                size = props.size
            }
        }
        // Setting these properties to null causes the picker to freeze.
        props.minTime?.let { minDate = it.toJSDate() }
        props.maxTime?.let { maxDate = it.toJSDate() }
        disabled = props.disabled
        readOnly = props.readOnly
        shouldDisableDate?.let { this.shouldDisableDate = it }
    }
}

val MyDatePickerMemo = memo(MyDatePicker)