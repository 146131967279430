package model

import kotlinx.datetime.Instant
import kotlinx.serialization.KSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object InstantAsEpochSeconds : KSerializer<Instant> {
    override val descriptor = PrimitiveSerialDescriptor("Instant", PrimitiveKind.LONG)
    override fun serialize(encoder: Encoder, value: Instant) = encoder.encodeLong(value.epochSeconds)
    override fun deserialize(decoder: Decoder) = Instant.fromEpochSeconds(decoder.decodeLong())
}

@Serializable
data class MyJwt(
    @SerialName("sub") val subject: String,
    @SerialName("iss") val issuer: String,
    @SerialName("aud") val audience: String,

    @SerialName("exp")
    @Serializable(with = InstantAsEpochSeconds::class)
    val expiration: Instant,

    @SerialName("nbf")
    @Serializable(with = InstantAsEpochSeconds::class)
    val notBefore: Instant,

    @SerialName("iat")
    @Serializable(with = InstantAsEpochSeconds::class)
    val issuedAt: Instant,

    val name: String,
    @SerialName("given_name") val givenName: String? = null,
    @SerialName("family_name") val familyName: String? = null,
    val email: String? = null,
    @SerialName("phone_number") val phoneNumber: String? = null,
    val groups: List<String> = emptyList()
)