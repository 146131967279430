package model.operation

import kotlinx.datetime.Instant
import kotlinx.datetime.toInstant
import kotlinx.datetime.toLocalDateTime
import model.*
import utils.timeZone
import kotlin.time.Duration.Companion.hours

data class OperationTimeJs(
    val start: Instant,
    val end: Instant,
    val startType: OperationTimeStartType,
    val endType: OperationTimeEndType
)

enum class OperationTimeStartType { DATE_AND_TIME, DATE }
enum class OperationTimeEndType { DATE_AND_TIME, DATE, UNDECIDED }

fun OperationTimeJs.toServer() = OperationTime(
    start = when (startType) {
        OperationTimeStartType.DATE -> OperationTimeStart.Date(start.toLocalDateTime(timeZone).date)
        OperationTimeStartType.DATE_AND_TIME -> OperationTimeStart.DateTime(start.toLocalDateTime(timeZone))
    },
    end = when (endType) {
        OperationTimeEndType.DATE -> OperationTimeEnd.Date(end.toLocalDateTime(timeZone).date)
        OperationTimeEndType.DATE_AND_TIME -> OperationTimeEnd.DateTime(end.toLocalDateTime(timeZone))
        OperationTimeEndType.UNDECIDED -> OperationTimeEnd.Undecided
    }
)

fun OperationTime.toJs(): OperationTimeJs {
    val startJs = when (start) {
        is OperationTimeStart.Date -> start.date.toLocalDateTime(LocalTime(12, 0))
        is OperationTimeStart.DateTime -> start.dateTime
    }.toInstant(timeZone)
    return OperationTimeJs(
        startType = when (start) {
            is OperationTimeStart.Date -> OperationTimeStartType.DATE
            is OperationTimeStart.DateTime -> OperationTimeStartType.DATE_AND_TIME
        },
        endType = when (end) {
            is OperationTimeEnd.Date -> OperationTimeEndType.DATE
            is OperationTimeEnd.DateTime -> OperationTimeEndType.DATE_AND_TIME
            OperationTimeEnd.Undecided -> OperationTimeEndType.UNDECIDED
        },
        start = startJs,
        end = when (end) {
            is OperationTimeEnd.Date -> end.date.toLocalDateTime(LocalTime(13, 0)).toInstant(timeZone)
            is OperationTimeEnd.DateTime -> end.dateTime.toInstant(timeZone)
            OperationTimeEnd.Undecided -> startJs + 1.hours
        }
    )
}