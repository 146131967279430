package styles

import csstype.Border
import csstype.LineStyle
import csstype.px
import csstype.rgba
import emotion.css.ClassName
import emotion.styled.styled
import mui.material.Stack
import react.dom.html.ReactHTML

val outlineBorder = Border(1.px, LineStyle.solid, rgba(0, 0, 0, 0.23))
val outlineBorderRadius = 4.px

val outlined = ClassName {
    border = outlineBorder
    borderRadius = outlineBorderRadius
}

val OutlinedDiv = ReactHTML.div.styled { _, _ ->
    border = outlineBorder
    borderRadius = outlineBorderRadius
    padding = 8.px
}

val OutlinedStack = Stack.styled { _, _ ->
    border = outlineBorder
    borderRadius = outlineBorderRadius
    padding = 8.px
}