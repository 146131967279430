import kotlinx.datetime.Clock
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.Month
import kotlin.random.Random

private val random = Random(Clock.System.now().nanosecondsOfSecond)
fun randomString(n: Int) = List(n) { ('a'..'z').random(random) }.joinToString("")
fun randomNumber(n: Int) = List(n) { ('0'..'9').random(random) }.joinToString("").toInt()
fun randomCode(n: Int) = List(n) { ('1'..'9').random(random) }.joinToString("").toInt()
fun randomId(n: Int = 16) = randomString(n)

/**
 * Could have used a lookbehind, but it's not supported in Safari.
 */
private val camelRegex = Regex("""([a-zA-Z])([A-Z])""")
fun String.camelToSpinalCase() = camelRegex.replace(this) {
    "${it.groupValues[1]}-${it.groupValues[2]}"
}.lowercase()

fun LocalDateTime.copy(
    year: Int? = null,
    month: Month? = null,
    dayOfMonth: Int? = null,
    hour: Int? = null,
    minute: Int? = null,
    second: Int? = null,
    nanoSecond: Int? = null
) = LocalDateTime(
    year ?: this.year,
    month ?: this.month,
    dayOfMonth ?: this.dayOfMonth,
    hour ?: this.hour,
    minute ?: this.minute,
    second ?: this.second,
    nanoSecond ?: this.nanosecond
)

fun <T : Comparable<T>> T.coerceIn(rangeList: List<ClosedRange<T>>): T {
    var maxDiff = Int.MAX_VALUE
    var bestMatch: T? = null
    for (it in rangeList) {
        val diffStart = compareTo(it.start)
        val diffEnd = it.endInclusive.compareTo(this)
        if (diffStart >= 0 && diffEnd >= 0) return this
        if (diffStart < maxDiff) {
            maxDiff = diffStart
            bestMatch = it.start
        }
        if (diffEnd < maxDiff) {
            maxDiff = diffEnd
            bestMatch = it.endInclusive
        }
    }
    return bestMatch ?: this
}