package components.editor

import csstype.FontWeight
import externals.SlateEditor
import externals.SlateModule
import externals.SlateRenderProps
import externals.SlateTextNode
import kotlinx.js.jso
import react.FC
import react.dom.events.KeyboardEvent
import react.dom.html.ReactHTML

external interface MyLeafNode : SlateTextNode {
    var bold: Boolean?
}

fun StyledTextNode(text: String, bold: Boolean = false) = jso<MyLeafNode> {
    this.text = text
    this.bold = bold
}

val Leaf = FC<SlateRenderProps<MyLeafNode>> {
    ReactHTML.span {
        setSlateProps(it)
        style = jso {
            fontWeight = if (it.leaf.bold == true) FontWeight.bold else FontWeight.normal
        }
    }
}

fun onKeyDown(event: KeyboardEvent<*>, editor: SlateEditor) {
    if (event.ctrlKey && event.key == "b") {
        event.preventDefault()
        SlateModule.Transforms.setNodes<MyLeafNode>(
            editor,
            jso { bold = true },
            jso { match = { SlateModule.Text.isText(it) }; split = true }
        )
    }
}