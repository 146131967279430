package helpers

import mu.KotlinLogging

object PlaceholderConverter {
    private val logger = KotlinLogging.logger("EditorConversion")
    val placeholderLookup = MessagePlaceholderType.values().associateBy { it.key }

    /** Finds the placeholder in group[2]. Group 1 captures new line and punctuations before the placeholder. **/
    val placeholderRegex = Regex("""([.:\n] *)?(<[^<]*>)""")

    fun resolve(
        value: String,
        placeholderMapping: Map<MessagePlaceholderType, MessagePlaceholderContent>?
    ) = buildString {
        resolveInternal(value, placeholderMapping,
            { append(it) },
            { _, value -> append(value) }
        )
    }

    inline fun resolveInternal(
        value: String,
        placeholderMapping: Map<MessagePlaceholderType, MessagePlaceholderContent>?,
        actionText: (String) -> Unit,
        actionPlaceholder: (MessagePlaceholderType, String) -> Unit
    ) {
        var lastStart = 0
        placeholderRegex.findAll(value).forEach { match ->
            match.groups[2]?.value?.let { matchValue ->
                placeholderLookup[matchValue]?.let { type ->
                    placeholderMapping?.get(type)?.let {
                        val firstLength = match.groups[1]?.value?.length ?: 0
                        val capitalize = match.range.first == 0 || firstLength > 0
                        val placeholderValue = if (capitalize) it.capitalized else it.lowercase
                        actionText(value.substring(lastStart, match.range.first + firstLength))
                        actionPlaceholder(type, placeholderValue)
                        lastStart = match.range.last + 1
                    }
                }
            }
        }
        actionText(value.substring(lastStart, value.length))
    }
}