package helpers

typealias PlaceholderMapping = Map<MessagePlaceholderType, MessagePlaceholderContent>

enum class MessagePlaceholderType(keyContent: String) {
    START_DAY("start:day"),
    START_DAY_DATE("start:day-date"),
    START_DATE("start:date"),

    END_DAY("end:day"),
    END_DAY_DATE("end:day-date"),
    END_DATE("end:date"),

    OPEN_DAY("open:day"),
    OPEN_DAY_DATE("open:day-date"),
    OPEN_DATE("open:date");

    val key = "<${keyContent}>"
    override fun toString() = key
}

class MessagePlaceholderContent(val lowercase: String, capitalized: String? = null) {
    val capitalized = capitalized ?: lowercase.replaceFirstChar { it.uppercase() }
}
