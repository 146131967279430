package components

import csstype.Display
import csstype.None
import data.UserResult
import data.useUserResult
import emotion.react.css
import mui.material.Alert
import mui.material.AlertColor
import react.*
import react.dom.html.ReactHTML.div
import views.login.LoginView

external interface AuthProps : Props {
    var componentType: ComponentType<Props>
    var props: Props?
}

val RequireAuthComponent = FC<AuthProps>("RequireAuth") { props ->
    val userResult = useUserResult()

    val user = when (userResult) {
        is UserResult.Failure -> {
            Alert {
                +"Error getting login status:\n ${userResult.e}"
                severity = AlertColor.error
            }
            null
        }
        is UserResult.Missing -> {
            // We want this to be re-rendered every time the user changes.
            LoginView()
            null
        }
        is UserResult.User -> userResult.user
    }


    // We want the state to be saved so input and such is not lost:
    div {
        key = "component"
        css {
            display = if (user == null) None.none else Display.block
        }
        props.props?.let {
            +createElement(props.componentType, it)
        } ?: run {
            +createElement(props.componentType)
        }
    }
}

fun <P : Props> requireAuth(componentType: ComponentType<P>, props: P? = null) =
    RequireAuthComponent.create {
        this.componentType = componentType.unsafeCast<ComponentType<Props>>()
        this.props = props
    }
