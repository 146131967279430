import externals.loadTimeZone
import kotlinx.browser.document
import react.create
import react.dom.client.createRoot
import views.AppView

fun main() {
    loadTimeZone()
    createRoot(document.getElementById("root")!!).render(
        AppView.create()
    )
}