package views.operationcreate

import mui.material.Size
import mui.material.ToggleButton
import mui.material.ToggleButtonGroup
import react.FC
import react.Props
import react.useMemo

data class Template(
    val name: String,
    val contentEng: String,
    val contentNor: String,
)

external interface TemplateSelectionProps : Props {
    var templates: Iterable<Template>
    var onClick: (template: Template) -> Unit
    var contentEng: String
    var contentNor: String
    var disabled: Boolean
}

inline fun <T : Any?, R> T.useMemo(crossinline block: (value: T) -> R) =
    useMemo(this) { block(this) }

val TemplateSelection = FC<TemplateSelectionProps> { props ->
    val templatesByName = props.templates.useMemo { it.associateBy { it.name } }
    val templatesByContent = props.templates.useMemo { it.associateBy { it.contentEng + it.contentNor } }
    val selectedTemplate = templatesByContent[props.contentEng + props.contentNor]

    ToggleButtonGroup {
        value = selectedTemplate?.name
        exclusive = true
        disabled = props.disabled
        size = Size.small
        onChange = { _, name ->
            if (name != null) props.onClick(templatesByName.getValue(name.unsafeCast<String>()))
        }
        props.templates.forEach { template ->
            ToggleButton {
                value = template.name
                +template.name
            }
        }
    }
}