package views.operation

import common.MaterialIcon
import csstype.*
import emotion.react.css
import emotion.styled.styled
import model.OperationServiceResult
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import react.FC
import react.Props
import react.dom.html.ReactHTML.span
import styles.outlineBorder
import views.operationcreate.Service

external interface ServiceResultProps : Props {
    var services: Array<out Service>
    var values: Array<OperationServiceResult<*>>
}

private val ServiceLabel = span.styled { _, _ ->
    letterSpacing = 0.045.rem
    fontSize = 0.9.rem
}

val ServiceResult = FC<ServiceResultProps>("ServiceResult") { props ->
    Stack {
        css {
            border = outlineBorder
            borderRadius = 4.px
            padding = 8.px
            if (props.values.any { it is OperationServiceResult.Failed }) {
                borderColor = Color("#d32f2f")
            }
        }
        spacing = responsive(1)
        props.services.forEachIndexed { index, service ->
            Stack {
                spacing = responsive(0.5)
                direction = responsive(StackDirection.row)
                when (val value = props.values[index]) {
                    is OperationServiceResult.Ok<*> -> {
                        MaterialIcon { name = "check_circle"; color = Color("#4caf50") }
                        ServiceLabel { +service.label }
                    }
                    is OperationServiceResult.Off -> {
                        MaterialIcon { name = "radio_button_unchecked"; color = Color("#777") }
                        ServiceLabel { +service.label }
                    }
                    is OperationServiceResult.Pending -> {
                        MaterialIcon { name = "pending"; color = Color("#03a9f4") }
                        ServiceLabel { +service.label }
                    }
                    is OperationServiceResult.Failed -> {
                        MaterialIcon { name = "error"; color = Color("#ef5350") }
                        Stack {
                            ServiceLabel { +service.label }
                            span {
                                css {
                                    fontSize = 0.875.rem
                                    overflowWrap = OverflowWrap.anywhere
                                }
                                +value.details
                            }
                        }
                    }
                }
            }
        }
    }
}