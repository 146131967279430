package views.operationcreate

import model.WeatherData
import react.FC
import react.Props
import react.StateSetter

external interface OperationWeatherStepProps : Props {
    var value: WeatherData
    var setter: StateSetter<WeatherData>?
}

inline fun <T> StateSetter<T>?.set(crossinline block: T.() -> T) = this?.invoke { it.block() }

val OperationWeatherStep = FC<OperationWeatherStepProps> { props ->
    CheckStep {
        prompt = "Have you checked with meteorologist on duty?"
        value = props.value.meteorologist
        onChange = { props.setter.set { copy(meteorologist = it) } }
    }
    CheckStep {
        prompt = "Have you checked with the short-term forecast from YR?"
        value = props.value.yr
        onChange = { props.setter.set { copy(yr = it) } }
    }
    CheckStep {
        prompt = "Have you checked our own weather station?"
        value = props.value.weatherStationInternal
        onChange = { props.setter.set { copy(weatherStationInternal = it) } }
    }
    CheckStep {
        prompt = "Have you checked the external weather station?"
        value = props.value.weatherStationExternal
        onChange = { props.setter.set { copy(weatherStationExternal = it) } }
    }
    CheckStep {
        prompt = "Are you certain?"
        value = props.value.certain
        onChange = { props.setter.set { copy(certain = it) } }
    }
}