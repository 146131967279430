package model

import kotlinx.serialization.Serializable

@Serializable
data class LoginInitRequest(
    val phone: String
)

@Serializable
data class LoginInitResponse(
    val requestId: String
)

@Serializable
data class LoginVerifyRequest(
    val requestId: String,
    val code: Int
)
