import framework.Api
import framework.Route
import model.*
import model.CreateOperationRequest
import model.operation.ExternalOperationAllItem
import model.operation.ExternalOperationResponse
import model.OperationItem

class Backend : Api("api") {
    val auth by ::Auth
    val operation by ::Operation
    val email by ::Email
    val openingHours by ::OpeningHours
    val external by ::External

    class Auth : Route() {
        val login by ::Login

        class Login : Route() {
            val initPost = postMethod<LoginInitRequest, LoginInitResponse>("init")
            val verifyPost = postMethod<LoginVerifyRequest, String>("verify")
        }
    }

    class Operation : Route() {
        val get = getMethod<List<OperationItem>>()
        val post = postMethod<CreateOperationRequest, OperationItem>()
        val item by parameter(::Item)

        class Item : Route() {
            val delete = deleteMethod<OperationItem>()
        }
    }

    class Email : Route() {
        val get = getMethod<EmailListResponse>()
        val patch = patchMethod<EmailPatch, EmailListResponse>()
    }

    class OpeningHours : Route() {
        val get = getMethod<List<OpeningHoursItem>>()
        val patch = patchMethod<OpeningHoursPatch, List<OpeningHoursItem>>()
    }

    class External : Route() {
        val operation by ::Operation
        val openingHours by ::OpeningHours

        class Operation : Route() {
            val websiteGet = getMethod<ExternalOperationResponse>("website")
            val displayGet = getMethod<ExternalOperationResponse>("display")
            val analyticsGet = getMethod<List<ExternalOperationAllItem>>("analytics")
        }

        class OpeningHours : Route() {
            val get = getMethod<OpeningHoursListResponseExternal>()
            val rangeGet = getMethod<OpeningHoursRangeResponse>("range")
        }
    }
}
