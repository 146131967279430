package views.operation

import views.operationcreate.Template

/*val myTemplates = arrayOf(
    Template(
        "Bad Weather",
        "The cable car is closed from ${MessagePlaceholderType.START_DAY_DATE} due to bad weather. We open again ${MessagePlaceholderType.OPEN_DAY_DATE}.",
        "Heisen er stengt fra ${MessagePlaceholderType.START_DAY_DATE} på grunn av dårlig vær. Vi åpner igjen ${MessagePlaceholderType.OPEN_DAY_DATE}."
    )
).toList()*/

object Templates {
    val WEATHER = Template(
        "Bad Weather",
        "The cable car is closed due to bad weather.",
        "Heisen er stengt på grunn av dårlig vær."
    )

    val LIST = listOf(WEATHER)
}
