package views.openinghours

import utils.DateFormatter
import components.MyTablePatch
import kotlinx.datetime.Clock
import model.PatchItem
import react.FC
import react.Props
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.th
import react.useCallback
import react.useMemo
import utils.timeZone
import views.OpeningHoursPatchItem

external interface OpeningHoursTableProps : Props {
    var data: List<OpeningHoursPatchItem>
    var selectedId: String?
    var onSelected: (OpeningHoursPatchItem) -> Unit
}

val OpeningHoursTable = FC<OpeningHoursTableProps> { props ->
    val dateFormatter = useMemo { DateFormatter(Clock.System.now(), timeZone) }
    MyTablePatch {
        data = props.data
        selectedId = props.selectedId
        onSelected = props.onSelected.unsafeCast<(PatchItem<*>) -> Unit>()
        headerCreator = useCallback {
            it.apply {
                th { +"Effective From" }
                locationValues.forEach {
                    th { +it.displayName }
                }
            }
        }
        rowCreator = useCallback(props.data) { builder, index, item ->
            item as OpeningHoursPatchItem
            builder.apply {
                td {
                    if (index == 0) {
                        +"Now"
                    } else {
                        +dateFormatter.get(item.details.startDate).engCap()
                    }
                }
                locationValues.forEach {
                    td {
                        if (index == 0) {
                            +""
                        } else {
                            val prev = it.prop.get(props.data[index - 1].details)
                            val curr = it.prop.get(item.details)
                            +(if (prev == curr) "=" else "≠")
                        }
                    }
                }
            }
        }
    }
}
