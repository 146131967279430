package components.editor

import csstype.*
import externals.*
import helpers.MessagePlaceholderType
import kotlinx.js.jso
import react.FC
import react.dom.html.ReactHTML

external interface SlatePlaceholderNode : SlateNode {
    var placeholderType: MessagePlaceholderType
    var value: String
}

const val elementTypePlaceholder = "placeholder"
val PlaceholderElement = FC<SlateRenderProps<SlatePlaceholderNode>> { props ->
    val selected = SlateReactModule.useSelected()
    val focused = SlateReactModule.useFocused()
    ReactHTML.span {
        setSlateProps(props)
        contentEditable = false
        style = jso {
            padding = Padding(2.px, 3.px)
            margin = Margin(0.px, 1.px)
            verticalAlign = VerticalAlign.baseline
            display = Display.inlineBlock
            borderRadius = 4.px
            backgroundColor = if (selected && focused) Color("#ddd") else Color("#eee")
            fontSize = 0.9.em
        }
        +props.element.value
    }
}

@Suppress("FunctionName")
fun PlaceholderNode(type: MessagePlaceholderType, value: String) = jso<SlatePlaceholderNode> {
    this.type = elementTypePlaceholder
    this.value = value
    placeholderType = type
    children = arrayOf(jso<SlateTextNode> { text = "" })
}

fun insertPlaceholderElement(editor: SlateEditor, type: MessagePlaceholderType, value: String) {
    SlateModule.Transforms.insertNodes(editor, arrayOf(PlaceholderNode(type, value)))
    SlateModule.Transforms.move(editor)
}

fun withPlaceholders(editor: SlateEditor) = editor.apply {
    val isVoidOld = isVoid
    val isInlineOld = isInline
    isVoid = { if (it.type == elementTypePlaceholder) true else isVoidOld(it) }
    isInline = { if (it.type == elementTypePlaceholder) true else isInlineOld(it) }
}