package views.openinghours

import common.IconType
import common.MaterialIcon
import components.time.TimePickerBase
import components.time.TimePickerBaseStyleProps
import components.time.TimePickerLocal
import csstype.Display
import csstype.FlexWrap
import csstype.JustifyContent
import emotion.react.css
import kotlinx.js.jso
import model.LocalTime
import model.OpeningDay
import mui.material.Checkbox
import mui.material.Size
import org.w3c.dom.HTMLInputElement
import react.*
import react.dom.events.ChangeEvent
import react.dom.html.ReactHTML.div

external interface OpeningDayProps : Props {
    var data: OpeningDay
    var onChange: (OpeningDay) -> Unit
    var readOnly: Boolean?
}

val OpeningDayView = FC<OpeningDayProps>("OpeningDayView") { props ->
    val closed = props.data == OpeningDay.CLOSED
    val onChangeStart = useCallback(props.onChange, props.data.end) { value: LocalTime ->
        props.onChange(OpeningDay(value, props.data.end))
    }
    val onChangeEnd = useCallback(props.onChange, props.data.start) { value: LocalTime ->
        props.onChange(OpeningDay(props.data.start, value))
    }
    val onChangeClosed = useCallback(props.onChange) { _: ChangeEvent<HTMLInputElement>, checked: Boolean ->
        if (checked) props.onChange(OpeningDay.CLOSED)
        else props.onChange(OpeningDay.DEFAULT)
    }
    val timePickerStyleProps = jso<TimePickerBaseStyleProps> {
        size = Size.small
        hideIcon = true
    }
    div {
        css {
            display = Display.flex
            flexWrap = FlexWrap.wrap
            justifyContent = JustifyContent.center
        }
        div {
            css {
                display = Display.flex
                flexWrap = FlexWrap.wrap
                justifyContent = JustifyContent.center
            }
            if (closed) {
                TimePickerBase {
                    this.value = "----:----"
                    this.disabled = true
                    +timePickerStyleProps
                }
                TimePickerBase {
                    this.value = "----:----"
                    this.disabled = true
                    +timePickerStyleProps
                }
            } else {
                TimePickerLocal {
                    value = props.data.start
                    onChange = onChangeStart
                    minuteStep = 15
                    readOnly = props.readOnly
                    +timePickerStyleProps
                }
                TimePickerLocal {
                    value = props.data.end
                    minuteStep = 15
                    onChange = onChangeEnd
                    readOnly = props.readOnly
                    +timePickerStyleProps
                }
            }
        }
        Checkbox {
            checked = closed
            onChange = onChangeClosed
            icon = MaterialIcon.create {
                name = "do_not_disturb_on"
                type = IconType.OUTLINED
            }
            checkedIcon = MaterialIcon.create {
                name = "do_not_disturb_on"
                type = IconType.FILLED
            }
            disabled = props.readOnly  // ReadOnly not working
        }
    }
}

val OpeningDayViewMemo = memo(OpeningDayView)
